import React from "react";
import { Button } from "antd";

class InviteButton extends React.Component {
  state = {
    open: false
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  /**
   * If clicked on outside of element
   */
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ open: false });
    }
  };

  handleBtnClick = () => this.setState({ open: !this.state.open });

  renderModal() {
    if (!this.state.open) {
      return null;
    }
    return (
      <div className="inviteContainer" ref={this.setWrapperRef}>
        <div>
          <h4 style={{ color: "white" }}>Share Link</h4>
        </div>
        <div className="linkBox">
          <span>{`${process.env.REACT_APP_CLIENT_BASE_URL}/room/${this.props.room}`}</span>
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderModal()}
        <Button
          className="ide-btn"
          style={{ marginRight: "8px" }}
          onClick={this.handleBtnClick}
        >
          Invite
        </Button>
      </React.Fragment>
    );
  }
}

export default InviteButton;
