import {
  FETCH_COMPILERS_STARTED,
  FETCH_COMPILERS_SUCCESS,
  FETCH_COMPILERS_FAILURE
} from "../actions/types";

const initialCompilersState = {
  loading: false,
  data: {},
  error: null
};

export const compilers = (state = initialCompilersState, action) => {
  switch (action.type) {
    case FETCH_COMPILERS_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_COMPILERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_COMPILERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
