import {
  FETCH_ACCOUNT_STARTED,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_FAILURE,
  CREATE_ACCOUNT_STARTED,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
  FETCH_TEAM_MEMBERS_STARTED,
  FETCH_TEAM_MEMBERS_SUCCESS,
  FETCH_TEAM_MEMBERS_FAILURE,
  CREATE_INVITE_STARTED,
  CREATE_INVITE_SUCCESS,
  CREATE_INVITE_FAILURE,
  FETCH_INVITE_STARTED,
  FETCH_INVITE_SUCCESS,
  FETCH_INVITE_FAILURE,
  RESEND_INVITE_STARTED,
  RESEND_INVITE_SUCCESS,
  RESEND_INVITE_FAILURE,
  CREATE_TEAM_MEMBER_STARTED,
  CREATE_TEAM_MEMBER_SUCCESS,
  CREATE_TEAM_MEMBER_FAILURE,
  DELETE_TEAM_MEMBER_STARTED,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_FAILURE,
  UPDATE_TEAM_MEMBER_STARTED,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_FAILURE,
  DELETE_SLACK_INTEGRATION_STARTED,
  DELETE_SLACK_INTEGRATION_SUCCESS,
  DELETE_SLACK_INTEGRATION_FAILURE
} from "../actions/types";

const initialAccountState = {
  loading: false,
  data: {},
  error: null
};

export const account = (state = initialAccountState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_ACCOUNT_STARTED:
      return {
        ...state,
        loading: true
      };
    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case CREATE_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DELETE_SLACK_INTEGRATION_STARTED:
      return {
        ...state,
        loading: true
      };
    case DELETE_SLACK_INTEGRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case DELETE_SLACK_INTEGRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const initialTeamMemberState = {
  loading: false,
  data: [],
  error: null
};

export const teamMembers = (state = initialTeamMemberState, action) => {
  switch (action.type) {
    case FETCH_TEAM_MEMBERS_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DELETE_TEAM_MEMBER_STARTED:
      return {
        ...state,
        loading: true
      };
    case DELETE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case DELETE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_TEAM_MEMBER_STARTED:
      return {
        ...state,
        loading: true
      };
    case UPDATE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case UPDATE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const initialInviteState = {
  loading: false,
  data: {},
  error: null
};

export const invite = (state = initialInviteState, action) => {
  switch (action.type) {
    case CREATE_INVITE_STARTED:
      return {
        ...state,
        loading: true
      };
    case CREATE_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case CREATE_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_INVITE_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case RESEND_INVITE_STARTED:
      return {
        ...state,
        loading: true
      };
    case RESEND_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case RESEND_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_TEAM_MEMBER_STARTED:
      return {
        ...state,
        loading: true
      };
    case CREATE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case CREATE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
