import { TOUR_START, TOUR_NEXT, TOUR_STOP } from "../actions/types";

const initialState = {
  data: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TOUR_START:
      return {
        ...state,
        data: action.payload
      };
    case TOUR_NEXT:
      return {
        ...state,
        data: action.payload
      };
    case TOUR_STOP:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
}
