import styled from "styled-components";
import HeaderSearch from "ant-design-pro/lib/HeaderSearch";
import { Icon, Avatar } from "antd";

export const RightEnd = styled.div`
  float: right;
  height: 100%;
  padding-right: 10px;
`;

export const StyledHeaderSearch = styled(HeaderSearch)`
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  transition: all 0.3s;
  height: 100%;
  margin: 0 12px;
  > i {
    font-size: 16px;
    vertical-align: middle;
    color: @text-color;
  }
  &:hover {
    background: transparent;
  }
`;

export const CurrentUserDropdown = styled.span`
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  transition: all 0.3s;
  height: 100%;
  > i {
    font-size: 16px;
    vertical-align: middle;
    color: @text-color;
  }
  &:hover,
  &:global(.ant-popover-open) {
    background: @primary-1;
  }
`;

export const StyledAvatar = styled(Avatar)`
  margin: 20px 8px 20px 0;
  color: @primary-color;
  background: rgba(255, 255, 255, 0.85);
  vertical-align: middle;
`;

export const ToggleSiderMenuIcon = styled(Icon)`
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: #1890ff;
  }
`;
