import React from "react";
import { connect } from "react-redux";

import { Card, Col, Row, Icon, Button } from "antd";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import isEmpty from "lodash.isempty";

import Loading from "../../UI/Loading";
import {
  fetchPlans,
  fetchSubscription,
  updateSubscription
} from "../../../actions";

class Plans extends React.PureComponent {
  componentDidMount() {
    if (!this.props.selectPlan) {
      this.props.fetchPlans();
      this.props.fetchSubscription();
    }
  }

  selectPlan(plan) {
    if (this.props.selectPlan) {
      return this.props.selectPlan(plan);
    } else {
      return this.props.updateSubscription(plan, () => {
        this.props.history.push("/settings");
      });
    }
  }
  render() {
    const { plans, subscription } = this.props;
    console.log(this.props);
    if (plans.loading || subscription.loading) {
      return <Loading height={"50vh"} />;
    }
    return (
      <React.Fragment>
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <h3>Plan upgrades and downgrades take place immediately.</h3>
          <h3>
            You will be prorated and credited for remaining time on your plan if
            you upgrade in the middle of a billing cycle.
          </h3>
        </div>
        <Row
          gutter={16}
          style={{ padding: "30px", margin: "0px auto", maxWidth: "1200px" }}
        >
          {plans.data.map((card, i) => {
            return (
              <Col span={8} key={card._id}>
                <Card
                  className="pricing-table"
                  headStyle={{
                    display: "flex"
                  }}
                  actions={
                    !isEmpty(subscription.data) &&
                    card.stripe.id === subscription.data.stripe.plan
                      ? [
                          <Link to={"/settings"}>
                            <Button
                              type="primary"
                              style={{ backgroundColor: "#455463" }}
                            >
                              Your Current Plan
                            </Button>
                          </Link>
                        ]
                      : [
                          <Button
                            type="primary"
                            onClick={() =>
                              this.selectPlan(card.stripe.nickname)
                            }
                            style={{ backgroundColor: "#455463" }}
                          >
                            Choose This Plan
                          </Button>
                        ]
                  }
                  title={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h4 className="plan-name">{card.stripe.product}</h4>
                      <div>
                        <b className="amount">
                          {card.stripe.amount === 0
                            ? "Free"
                            : `$${card.stripe.amount / 100}`}
                        </b>
                        {card.stripe.amount === 0 ? null : (
                          <span className="interval">Monthly</span>
                        )}
                      </div>
                    </div>
                  }
                  bordered={false}
                >
                  <ul style={{ listStyle: "none", padding: 0 }}>
                    <li>
                      <Icon
                        type="check"
                        style={{ marginRight: "20px", color: "#4736ca" }}
                      />
                      <span className="feature">{`${
                        card.max_included_interviews
                      } interviews`}</span>
                    </li>
                    <li>
                      <Icon
                        type="check"
                        style={{ marginRight: "20px", color: "#4736ca" }}
                      />
                      <span className="feature">{`$10 per interview in excess of ${
                        card.max_included_interviews
                      }`}</span>
                    </li>
                    <li>
                      <Icon
                        type="check"
                        style={{ marginRight: "20px", color: "#4736ca" }}
                      />
                      <span className="feature">Unlimited Team Members</span>
                    </li>
                    <li>
                      <Icon
                        type="check"
                        style={{ marginRight: "20px", color: "#4736ca" }}
                      />
                      <span className="feature">
                        Unlimited Question Templates
                      </span>
                    </li>
                    <li>
                      <Icon
                        type="check"
                        style={{ marginRight: "20px", color: "#4736ca" }}
                      />
                      <span className="feature">Slack Integration</span>
                    </li>
                  </ul>
                </Card>
              </Col>
            );
          })}
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth, plans, subscription }) => ({
  auth,
  plans,
  subscription
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { fetchPlans, fetchSubscription, updateSubscription }
  )
)(Plans);
