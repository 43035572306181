import React from "react";
import AuthForm from "./Form";
import { fetchInvite } from "../../actions";
import { connect } from "react-redux";

class Invitation extends React.Component {
  componentDidMount() {
    const {
      params: { token }
    } = this.props.match;
    this.props.fetchInvite(token);
  }

  render() {
    const { invite } = this.props;
    if (!invite.loading) {
      return  <AuthForm type="invitation" />;
      
    }
    return null;
  }
}

const mapStateToProps = ({ invite }) => ({ invite });

export default connect(
  mapStateToProps,
  { fetchInvite }
)(Invitation);
