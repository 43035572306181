export const FETCH_CURRENT_USER_STARTED = "fetch_current_user_started";
export const FETCH_CURRENT_USER_SUCCESS = "fetch_current_user_success";
export const FETCH_CURRENT_USER_FAILURE = "fetch_current_user_failure";

export const LOGIN_USER_STARTED = "login_user_started";
export const LOGIN_USER_SUCCESS = "login_user_success";
export const LOGIN_USER_FAILURE = "login_user_failure";

export const SIGNUP_USER_STARTED = "signup_user_started";
export const SIGNUP_USER_SUCCESS = "signup_user_success";
export const SIGNUP_USER_FAILURE = "signup_user_failure";

export const LOGOUT_USER = "logout_user";

export const CLEAR_AUTH_STATE = "clear_auth_state";

export const FETCH_COMPILERS_STARTED = "fetch_compilers_started";
export const FETCH_COMPILERS_SUCCESS = "fetch_compilers_success";
export const FETCH_COMPILERS_FAILURE = "fetch_compilers_failure";

export const FETCH_INTERVIEWS_STARTED = "fetch_interviews_started";
export const FETCH_INTERVIEWS_SUCCESS = "fetch_interviews_success";
export const FETCH_INTERVIEWS_FAILURE = "fetch_interviews_failure";

export const FETCH_ONE_INTERVIEW_STARTED = "fetch_one_interview_started";
export const FETCH_ONE_INTERVIEW_SUCCESS = "fetch_one_interview_success";
export const FETCH_ONE_INTERVIEW_FAILURE = "fetch_one_interview_failure";

export const CREATE_INTERVIEWS_STARTED = "create_interviews_started";
export const CREATE_INTERVIEWS_SUCCESS = "create_interviews_success";
export const CREATE_INTERVIEWS_FAILURE = "create_interviews_failure";

export const DELETE_INTERVIEWS_STARTED = "delete_interviews_started";
export const DELETE_INTERVIEWS_SUCCESS = "delete_interviews_success";
export const DELETE_INTERVIEWS_FAILURE = "delete_interviews_failure";

export const UPDATE_INTERVIEWS_STARTED = "update_interviews_started";
export const UPDATE_INTERVIEWS_SUCCESS = "update_interviews_success";
export const UPDATE_INTERVIEWS_FAILURE = "update_interviews_failure";

export const FETCH_ACCOUNT_STARTED = "fetch_account_started";
export const FETCH_ACCOUNT_SUCCESS = "fetch_account_success";
export const FETCH_ACCOUNT_FAILURE = "fetch_account_failure";

export const FETCH_TEAM_MEMBERS_STARTED = "fetch_team_members_started";
export const FETCH_TEAM_MEMBERS_SUCCESS = "fetch_team_members_success";
export const FETCH_TEAM_MEMBERS_FAILURE = "fetch_team_members_failure";

export const CREATE_INVITE_STARTED = "create_invite_started";
export const CREATE_INVITE_SUCCESS = "create_invite_success";
export const CREATE_INVITE_FAILURE = "create_invite_failure";

export const FETCH_INVITE_STARTED = "fetch_invite_started";
export const FETCH_INVITE_SUCCESS = "fetch_invite_success";
export const FETCH_INVITE_FAILURE = "fetch_invite_failure";

export const RESEND_INVITE_STARTED = "resend_invite_started";
export const RESEND_INVITE_SUCCESS = "resend_invite_success";
export const RESEND_INVITE_FAILURE = "resend_invite_failure";

export const CREATE_TEAM_MEMBER_STARTED = "create_team_member_started";
export const CREATE_TEAM_MEMBER_SUCCESS = "create_team_member_success";
export const CREATE_TEAM_MEMBER_FAILURE = "create_team_member_failure";

export const DELETE_TEAM_MEMBER_STARTED = "delete_team_member_started";
export const DELETE_TEAM_MEMBER_SUCCESS = "delete_team_member_success";
export const DELETE_TEAM_MEMBER_FAILURE = "delete_team_member_failure";

export const UPDATE_TEAM_MEMBER_STARTED = "update_team_member_started";
export const UPDATE_TEAM_MEMBER_SUCCESS = "update_team_member_success";
export const UPDATE_TEAM_MEMBER_FAILURE = "update_team_member_failure";

export const CREATE_ACCOUNT_STARTED = "create_account_started";
export const CREATE_ACCOUNT_SUCCESS = "create_account_success";
export const CREATE_ACCOUNT_FAILURE = "create_account_failure";

export const FETCH_QUESTIONS_STARTED = "fetch_questions_started";
export const FETCH_QUESTIONS_SUCCESS = "fetch_questions_success";
export const FETCH_QUESTIONS_FAILURE = "fetch_questions_failure";

export const CREATE_QUESTIONS_STARTED = "create_questions_started";
export const CREATE_QUESTIONS_SUCCESS = "create_questions_success";
export const CREATE_QUESTIONS_FAILURE = "create_questions_failure";

export const DELETE_QUESTIONS_STARTED = "delete_questions_started";
export const DELETE_QUESTIONS_SUCCESS = "delete_questions_success";
export const DELETE_QUESTIONS_FAILURE = "delete_questions_failure";

export const UPDATE_QUESTIONS_STARTED = "update_questions_started";
export const UPDATE_QUESTIONS_SUCCESS = "update_questions_success";
export const UPDATE_QUESTIONS_FAILURE = "update_questions_failure";

export const IMPORT_QUESTIONS_STARTED = "import_questions_started";
export const IMPORT_QUESTIONS_SUCCESS = "import_questions_success";
export const IMPORT_QUESTIONS_FAILURE = "import_questions_failure";

export const DELETE_SLACK_INTEGRATION_STARTED =
  "delete_slack_integration_started";
export const DELETE_SLACK_INTEGRATION_SUCCESS =
  "delete_slack_integration_success";
export const DELETE_SLACK_INTEGRATION_FAILURE =
  "delete_slack_integration_failure";

export const RUN_TESTS_STARTED = "run_tests_started";
export const RUN_TESTS_SUCCESS = "run_tests_success";
export const RUN_TESTS_FAILURE = "run_tests_failure";

export const FETCH_SUBSCRIPTION_STARTED = "fetch_subscription_started";
export const FETCH_SUBSCRIPTION_SUCCESS = "fetch_subscription_success";
export const FETCH_SUBSCRIPTION_FAILURE = "fetch_subscription_failure";

export const CREATE_SUBSCRIPTION_STARTED = "create_subscription_started";
export const CREATE_SUBSCRIPTION_SUCCESS = "create_subscription_success";
export const CREATE_SUBSCRIPTION_FAILURE = "create_subscription_failure";

export const UPDATE_SUBSCRIPTION_STARTED = "update_subscription_started";
export const UPDATE_SUBSCRIPTION_SUCCESS = "update_subscription_success";
export const UPDATE_SUBSCRIPTION_FAILURE = "update_subscription_failure";

export const DELETE_SUBSCRIPTION_STARTED = "delete_subscription_started";
export const DELETE_SUBSCRIPTION_SUCCESS = "delete_subscription_success";
export const DELETE_SUBSCRIPTION_FAILURE = "delete_subscription_failure";

export const UPDATE_PAYMENT_SOURCE_STARTED = "update_paymemt_source_started";
export const UPDATE_PAYMENT_SOURCE_SUCCESS = "update_paymemt_source_success";
export const UPDATE_PAYMENT_SOURCE_FAILURE = "update_paymemt_source_failure";

export const DELETE_PAYMENT_SOURCE_STARTED = "delete_paymemt_source_started";
export const DELETE_PAYMENT_SOURCE_SUCCESS = "delete_paymemt_source_success";
export const DELETE_PAYMENT_SOURCE_FAILURE = "delete_paymemt_source_failure";

export const FETCH_PLANS_STARTED = "fetch_plans_started";
export const FETCH_PLANS_SUCCESS = "fetch_plans_success";
export const FETCH_PLANS_FAILURE = "fetch_plans_failure";

export const FETCH_INVOICE_STARTED = "fetch_invoice_started";
export const FETCH_INVOICE_SUCCESS = "fetch_invoice_success";
export const FETCH_INVOICE_FAILURE = "fetch_invoice_failure";

export const TOUR_START = "tour_start";
export const TOUR_NEXT = "tour_next";
export const TOUR_STOP = "tour_stop";

export const UPDATE_TOUR_STARTED = "update_tour_started";
export const UPDATE_TOUR_SUCCESS = "update_tour_success";
export const UPDATE_TOUR_FAILURE = "update_tour_failure";
