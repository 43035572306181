export default {
  Bash: {
    name: "bash",
    solution: {
      boilerplate: 'for i in `seq 1 5`;\ndo\n  echo "Hello, World!"\ndone',
      sourceFile: "solution.sh"
    },
    test: {
      testable: false,
      boilerplate: "",
      sourceFile: ""
    },
    comments: {
      type: "single",
      prefix: "#",
      suffix: null
    }
  },
  C: {
    name: "c",
    solution: {
      boilerplate:
        '#include <stdio.h>\n\n// To execute your C program, please define "int main()"\n\nint main() {\n  for (int i = 0; i < 5; i++) {\n    printf("Hello, World!\\n");\n  }\n  return 0;\n}',
      sourceFile: "solution.c"
    },
    test: {
      testable: false,
      boilerplate: "",
      sourceFile: ""
    },
    comments: {
      type: "single",
      prefix: "//",
      suffix: null
    }
  },
  "C#": {
    name: "c#",
    solution: {
      boilerplate:
        'using System;\n\npublic class Solution\n{\n    \n    static void Main(string[] args)\n    {\n        Solution solution = new Solution();\n        Console.WriteLine($"{solution.Hello()}");\n    }\n\n    public string Hello()\n    {\n        return "Hello World!";\n    }\n\n}',
      sourceFile: "Solution.cs"
    },
    test: {
      testable: true,
      boilerplate:
        'using NUnit.Framework;\n\npublic class SolutionTest\n{\n\n    [Test]\n    public void Test1()\n    {\n        Solution solution = new Solution();\n\n        Assert.AreEqual(solution.Hello(), "Hello World!");\n    }\n}',
      sourceFile: "SolutionTest.cs"
    },
    comments: {
      type: "single",
      prefix: "//",
      suffix: null
    }
  },
  "C++": {
    name: "c++",
    solution: {
      boilerplate:
        '#include <iostream>\nusing namespace std;\n\n// To execute your C++ program, please define "int main()"\nint main() {\n  std::cout << "Hello, World!\\n";\n}',
      sourceFile: "solution.cpp"
    },
    test: {
      testable: false,
      boilerplate: "",
      sourceFile: ""
    },
    comments: {
      type: "single",
      prefix: "//",
      suffix: null
    }
  },
  // Clojure: {
  //   name: "clojure",
  //   boilerplate:
  //     '(defn hello [times]\n  (prn "Hello, World")\n  (cond (> times 1) (hello (- times 1)))\n)\n\n(hello 5)',
  //   comments: {
  //     type: "single",
  //     prefix: ";",
  //     suffix: null
  //   }
  // },
  Elixir: {
    name: "elixir",
    solution: {
      boilerplate:
        'defmodule Solution do\n    def hello do\n        IO.puts "Hello, World!"\n        "hello"\n    end\nend',
      sourceFile: "solution.ex"
    },
    test: {
      testable: true,
      boilerplate:
        'ExUnit.start\n\ndefmodule SolutionTest do\n    use ExUnit.Case\n    test "greets the world" do\n        assert Solution.hello() == "Hello, World"\n    end\nend\n',
      sourceFile: "solution_test.exs"
    },
    comments: {
      type: "single",
      prefix: "#",
      suffix: null
    }
  },
  // Erlang: {
  //   name: "erlang",
  //   boilerplate:
  //     '-module(solution).\n-export([start/0]).\n\n% To execute your Erlang program, please declare the module as "solution"\n% and define a method named "start" on it.\n\nstart() ->\n  hello(5).\n\nhello(0) ->\n  0;\nhello(N) ->\n  io:format("Hello, World~n"),\n  hello(N - 1).',
  //   comments: {
  //     type: "single",
  //     prefix: "%",
  //     suffix: null
  //   }
  // },
  "F#": {
    name: "f#",
    solution: {
      boilerplate:
        'open System\n\nmodule Hello =\n    let SayHello = "Hello, World!"\n\n[<EntryPoint>]\nlet main argv =\n    printfn "%s" Hello.SayHello\n    0 // return an integer exit code',
      sourceFile: "Solution.fs"
    },
    test: {
      testable: true,
      boilerplate:
        'namespace Tests\n\nopen NUnit.Framework\nopen Solution.Hello\n\ntype TestClass () =\n\n    [<SetUp>]\n    member this.Setup () =\n        ()\n\n    [<Test>]\n    member this.shouldSayHello () =\n        Assert.AreEqual(SayHello, "Hello, World!")',
      sourceFile: "SolutionTest.fs"
    },
    comments: {
      type: "single",
      prefix: "//",
      suffix: null
    }
  },
  Golang: {
    name: "golang",
    solution: {
      boilerplate:
        '// To execute your Golang program, please declare \n// a func main() in a package "main"\n\npackage main\n\nimport "fmt"\n\nfunc Hello() string {\n  return "Hello, World"\n}\n\nfunc main() {\n  for i := 0; i < 5; i++ {\n    fmt.Println(Hello())\n  }\n}',
      sourceFile: "main.go"
    },
    test: {
      testable: true,
      boilerplate:
        'package main\n\nimport "testing"\n\nfunc TestHello(t *testing.T) {\n  if Hello() != "Hello, World" {\n    t.Error("Expected Hello() to equal "Hello, World"")\n  }\n}',
      sourceFile: "main_test.go"
    },
    comments: {
      type: "single",
      prefix: "//",
      suffix: null
    }
  },
  // Haskell: {
  //   name: "haskell",
  //   boilerplate: 'main = putStrLn "Hello, World!"',
  //   comments: {
  //     type: "single",
  //     prefix: "--",
  //     suffix: null
  //   }
  // },
  "HTML/CSS/JS": {
    name: "HTML/CSS/JS",
    solution: {
      boilerplate:
        'import React from "react";\nimport ReactDOM from "react-dom";\n\nclass ShoppingList extends React.Component {\n  render() {\n    return (\n      <div className="shopping-list">\n        <h1>Shopping List</h1>\n        <ul>\n          <li>Oranges</li>\n          <li>Apples</li>\n          <li>Cherries</li>\n        </ul>\n      </div>\n    );\n  }\n}\n\nReactDOM.render(<ShoppingList/>, document.getElementById("root"))',
      sourceFile: "index.js"
    },
    test: {
      testable: true,
      boilerplate: `{
  "main": "./index.js",
  "dependencies": {
    "uuid": "latest",
    "react":"latest",
    "react-dom":"latest"
  }
}`,
      sourceFile: "package.json"
    },
    comments: {
      type: "single",
      prefix: "//",
      suffix: null
    }
  },
  Java: {
    name: "java",
    solution: {
      boilerplate:
        'import java.io.*;\nimport java.util.*;\n/*\n  * To execute your Java program, please define "static void main" on a class\n  * named Solution.\n  *\n  * If you need more classes, simply define them inline.\n  */\n\nclass Solution {\n\n  public static String hello() {\n    return "Hello, World!";\n  }\n\n  public static void main(String[] args) {\n    ArrayList<String> strings = new ArrayList<String>();\n    strings.add(hello());\n    strings.add("This editor is running Java " + Runtime.version().feature());\n\n    for (String string : strings) {\n      System.out.println(string);\n    }\n  }\n}',
      sourceFile: "Solution.java"
    },
    test: {
      testable: true,
      boilerplate:
        'import org.junit.*;\nimport org.junit.runner.*;\n\npublic class SolutionTest {\n  @Test\n  public void testHello() {\n    // Assert\n    Assert.assertEquals(Solution.hello(), "Hello, World!");\n  }\n}',
      sourceFile: "SolutionTest.java"
    },
    comments: {
      type: "single",
      prefix: "//",
      suffix: null
    }
  },
  JavaScript: {
    name: "javascript",
    solution: {
      boilerplate:
        'const _ = require("lodash");\n\nconst hello = n => {\n  return _.times(n, () => "Hello, World");\n};\n\nmodule.exports = { hello };\n',
      sourceFile: "solution.js"
    },
    test: {
      testable: true,
      boilerplate:
        'const assert = require("assert");\nconst { hello } = require("./solution.js");\n\ndescribe("hello()", function() {\n  describe("hello(1)", function() {\n    it(\'should return ["Hello, World"]\', function() {\n      assert.deepEqual(hello(1), ["Hello, World"]);\n    });\n  });\n  describe("hello(2)", function() {\n    it(\'should return ["Hello, World", "Hello, World"]\', function() {\n      assert.deepEqual(hello(2), ["Hello, World", "Hello, World"]);\n    });\n  });\n\n});',
      sourceFile: "test.js"
    },
    comments: {
      type: "single",
      prefix: "//",
      suffix: null
    }
  },
  // Kotlin: {
  //   name: "kotlin",
  //   boilerplate:
  //     '// To execute your Kotlin program, please define a top level function named main\n\nfun main(args: Array<String>) {\n  for (i in 1..5) println("Hello, World!")\n}',
  //   comments: {
  //     type: "single",
  //     prefix: "//",
  //     suffix: null
  //   }
  // },
  // OCaml: {
  //   name: "ocaml",
  //   boilerplate:
  //     'let rec hello n =\n  match n with\n  | 0 -> ()\n  | _ -> print_endline "Hello, World!"; hello (n-1);;\n\nhello 5;;',
  //   comments: {
  //     type: "multi",
  //     prefix: "(*",
  //     suffix: "*)"
  //   }
  // },
  // Perl: {
  //   name: "perl",
  //   boilerplate:
  //     'use strict;\nuse warnings;\nuse Data::Dumper;\n\nfor (my $count = 5; $count >= 1; $count--) {\n  print "Hello, World #$count\n";\n}',
  //   comments: {
  //     type: "single",
  //     prefix: "#",
  //     suffix: null
  //   }
  // },
  PHP: {
    name: "php",
    solution: {
      boilerplate:
        '<?php\n\n// To execute your PHP program, please wrap your code in <?php tags\n\ndeclare(strict_types=1);\n\nfinal class Hello\n{\n  public static function toString(): string\n    {\n        return "Hello, World";\n    }\n}\n?>',
      sourceFile: "solution.php"
    },
    test: {
      testable: true,
      boilerplate:
        "\n<?php\ndeclare(strict_types=1);\nrequire_once('solution.php');\n\nuse PHPUnitFrameworkTestCase;\n\nfinal class HelloTest extends TestCase\n{\n    public function testCanBeUsedAsString(): void\n    {\n        $this->assertEquals(\n            'Hello, World',\n            Hello::toString()\n        );\n    }\n}\n?>",
      sourceFile: "test.php"
    },
    comments: {
      type: "single",
      prefix: "//",
      suffix: null
    }
  },
  Python2: {
    name: "python2",
    solution: {
      boilerplate:
        'def hello():\n    return "Hello, World"\n\nfor i in xrange(5):\n    print hello()',
      sourceFile: "solution.py"
    },
    test: {
      testable: true,
      boilerplate:
        "import unittest\nfrom solution import hello\n\nclass TestHello(unittest.TestCase):\n\n    def test_hello(self):\n        self.assertEqual( hello(), \"Hello, World\")\n\nif __name__ == '__main__':\n    unittest.main()",
      sourceFile: "test.py"
    },
    comments: {
      type: "single",
      prefix: "#",
      suffix: null
    }
  },
  Python3: {
    name: "python3",
    solution: {
      boilerplate:
        'def hello():\n    return "Hello, World"\n\nfor i in range(5):\n    print(hello())',
      sourceFile: "solution.py"
    },
    test: {
      testable: true,
      boilerplate:
        "import unittest\nfrom solution import hello\n\nclass TestHello(unittest.TestCase):\n\n    def test_hello(self):\n        self.assertEqual( hello(), \"Hello, World\")\n\nif __name__ == '__main__':\n    unittest.main()",

      sourceFile: "test.py"
    },
    comments: {
      type: "single",
      prefix: "#",
      suffix: null
    }
  },
  // R: {
  //   compiler: "r",
  //   boilerplate: 'for(i in 1:5) {\n  print(paste("Hello, World"))\n}',
  //   comments: {
  //     type: "single",
  //     prefix: "#",
  //     suffix: null
  //   }
  // },
  Ruby: {
    name: "ruby",
    solution: {
      boilerplate:
        'class Hello\n  def toString\n      "Hello, World"\n  end\nend\n\nputs Hello.new.toString',
      sourceFile: "solution.rb"
    },
    test: {
      testable: true,
      boilerplate:
        "require 'minitest/autorun'\nrequire_relative 'solution'\n\ndescribe Hello do\n  it 'must print \"Hello, World\"' do\n    Hello.new.toString.must_equal 'Hello, World'\n  end\nend",
      sourceFile: "test.rb"
    },
    comments: {
      type: "single",
      prefix: "#",
      suffix: null
    }
  },
  // Rust: {
  //   name: "rust",
  //   boilerplate:
  //     'fn main() {\n  for n in 0..5 {\n    println!("{} - Hello, World!", n);\n  }\n}',
  //   comments: {
  //     type: "single",
  //     prefix: "//",
  //     suffix: null
  //   }
  // },
  Scala: {
    name: "scala",
    solution: {
      boilerplate:
        '// To execute your Scala program, please define an object named Solution that extends App\n\nobject Solution extends Greeting with App {\n  println(greeting)\n}\n\ntrait Greeting {\n  lazy val greeting: String = "Hello, World!"\n}',
      sourceFile: "Solution.scala"
    },
    test: {
      testable: true,
      boilerplate:
        'import org.scalatest._\n\nclass SolutionSpec extends FlatSpec with Matchers {\n  "The Hello object" should "say Hello, World!" in {\n    Solution.greeting shouldEqual "Hello, World!"\n  }\n}',
      sourceFile: "SolutionSpec.scala"
    },
    comments: {
      type: "single",
      prefix: "//",
      suffix: null
    }
  }
  // Swift: {
  //   name: "swift",
  //   boilerplate:
  //     'import Foundation\n\nfor _ in 1...5 {\n  print("Hello, World!")\n}',
  //   comments: {
  //     type: "single",
  //     prefix: "//",
  //     suffix: null
  //   }
  // },
  // "Visual Basic": {
  //   name: "vb",
  //   boilerplate:
  //     '\' To execute your Visual Basic program, please define a "Sub Main"\n\' on the top level Module\n\nModule Solution\n    Sub Main()\n        Console.WriteLine ("Hello, World")\n    End Sub\nEnd Module',
  //   comments: {
  //     type: "single",
  //     prefix: "'",
  //     suffix: null
  //   }
  // }
};
