import React, { Component } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import registerElixirMode from "codemirror-mode-elixir";

import "codemirror/mode/clike/clike";
import "codemirror/mode/clojure/clojure";
import "codemirror/mode/erlang/erlang";
import "codemirror/mode/go/go";
import "codemirror/mode/haskell/haskell";
import "codemirror/mode/javascript/javascript";
// import "codemirror/mode/kotlin/kotlin";
import "codemirror/mode/mllike/mllike";
import "codemirror/mode/perl/perl";
import "codemirror/mode/php/php";
import "codemirror/mode/python/python";
import "codemirror/mode/r/r";
import "codemirror/mode/ruby/ruby";
import "codemirror/mode/rust/rust";
// import "codemirror/mode/scala/scala";
import "codemirror/mode/shell/shell";
import "codemirror/mode/swift/swift";
import "codemirror/mode/vb/vb";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";

const modes = {
  bash: "text/x-sh",
  c: "text/x-csrc",
  "c#": "text/x-csharp",
  "c++": "text/x-c++src",
  clojure: "text/x-clojure",
  elixir: "elixir",
  erlang: "text/x-erlang",
  "f#": "text/x-fsharp",
  golang: "text/x-go",
  haskell: "text/x-haskell",
  java: "text/x-java",
  javascript: "text/javascript",
  kotlin: "text/x-kotlin",
  ocaml: "text/x-ocaml",
  perl: "text/x-perl",
  php: "application/x-httpd-php",
  python2: "text/x-python",
  python3: "text/x-python",
  r: "text/x-rsrc",
  ruby: "text/x-ruby",
  rust: "text/x-rustsrc",
  scala: "text/x-scala",
  swift: "text/x-swift",
  vb: "text/x-vb",
  "HTML/CSS/JS" : "text/jsx"
};

class CodeMirrorEditor extends Component {
  constructor(props) {
    super(props);
    this.instance = null;
  }

  render() {
    const {
      language,
      lineNumbers,
      code,
      onEditorChange,
      theme,
      readOnly,
      className
    } = this.props;
    let mode = modes[language];
    return (
      <CodeMirror
        editorDidMount={editor => {
          this.instance = editor;
          console.log(this.instance);
        }}
        value={code}
        options={{
          mode,
          theme: theme ? theme : "default",
          lineNumbers,
          readOnly: readOnly ? readOnly : false
        }}
        onBeforeChange={onEditorChange}
        className={className ? className : ""}
      />
    );
  }
}
export default CodeMirrorEditor;
