import axios from "axios";
import {
  CREATE_INVITE_STARTED,
  CREATE_INVITE_SUCCESS,
  CREATE_INVITE_FAILURE,
  FETCH_INVITE_STARTED,
  FETCH_INVITE_SUCCESS,
  FETCH_INVITE_FAILURE,
  RESEND_INVITE_STARTED,
  RESEND_INVITE_SUCCESS,
  RESEND_INVITE_FAILURE
} from "./types";

import { fetchTeamMembers } from "./index";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const createInvite = (email, accessRole) => async dispatch => {
  try {
    dispatch({ type: CREATE_INVITE_STARTED });
    let res = await axios.post(
      `${API_BASE_URL}/api/v1/account/team/invite`,
      {
        email,
        accessRole
      },
      { withCredentials: true }
    );
    let { invite } = res.data;
    dispatch(fetchTeamMembers());
    dispatch({ type: CREATE_INVITE_SUCCESS, payload: invite });
  } catch (error) {
    dispatch({ type: CREATE_INVITE_FAILURE, payload: error.response.data });
  }
};

export const fetchInvite = token => async dispatch => {
  try {
    dispatch({ type: FETCH_INVITE_STARTED });
    const res = await axios.get(
      `${API_BASE_URL}/api/v1/account/invite/${token}`,
      {
        withCredentials: true
      }
    );
    let { invite } = res.data;
    dispatch({ type: FETCH_INVITE_SUCCESS, payload: invite });
  } catch (error) {
    dispatch({
      type: FETCH_INVITE_FAILURE,
      payload: error.response.data
    });
  }
};

export const resendInvite = (token, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: RESEND_INVITE_STARTED });
    const res = await axios.get(
      `${API_BASE_URL}/api/v1/account/invite/resend/${token}`,
      {
        withCredentials: true
      }
    );
    let { invite } = res.data;
    callback("success", "Invite email sent!");
    dispatch({ type: RESEND_INVITE_SUCCESS, payload: invite });
  } catch (error) {
    callback("error", "Failed to send invite email!");
    dispatch({
      type: RESEND_INVITE_FAILURE,
      payload: error.response.data
    });
  }
};
