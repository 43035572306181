export default {
  //CODE_ROOM
  CHANGE_CODE_ROOM_SOLUTION: "CHANGE_CODE_ROOM_SOLUTION",
  ADD_CODE_ROOM_USER: "ADD_CODE_ROOM_USER",
  CODE_ROOM_USER_JOINED: "CODE_ROOM_USER_JOINED",
  CODE_ROOM_USER_LEFT: "CODE_ROOM_USER_LEFT",
  ENTER_CODE_ROOM: "ENTER_CODE_ROOM",
  CODE_ROOM_TERMINAL_CHANGE: "CODE_ROOM_TERMINAL_CHANGE",
  RUN_CODE_ROOM_SOLUTION: "RUN_CODE_ROOM_SOLUTION",
  RESET_CODE_ROOM: "RESET_CODE_ROOM",
  FINISH_CODE_ROOM_INTERVIEW: "FINISH_CODE_ROOM_INTERVIEW",
  SAVE_CODE_ROOM_INTERVIEWER_NOTES: "SAVE_CODE_ROOM_INTERVIEWER_NOTES",
  SAVE_CODE_ROOM_SNAPSHOT: "SAVE_CODE_ROOM_SNAPSHOT",
  ENTER_CODE_ROOM_PLAYBACK: "ENTER_CODE_ROOM_PLAYBACK",

  //CODE_CHALLENGE
  FETCH_CODE_CHALLENGE_QUESTIONS: "FETCH_CODE_CHALLENGE_QUESTIONS",
  RUN_CODE_CHALLENGE_TESTS: "RUN_CODE_CHALLENGE_TESTS",
  RUN_CODE_CHALLENGE_SOLUTION: "RUN_CODE_CHALLENGE_SOLUTION",
  CODE_CHALLENGE_TERMINAL_CHANGE: "CODE_CHALLENGE_TERMINAL_CHANGE"
};
