import React from "react";
import { connect } from "react-redux";
import { Button, Select, Icon, Tabs } from "antd";

import LanguageInfoModal from "./LanguageInfoModal";

import { languages } from "../../constants";

class LanguageSelect extends React.Component {
  state = {
    visible: false
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  /**
   * If clicked on outside of element
   */
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ visible: false });
    }
  };

  handleBtnClick = () => this.setState({ visible: !this.state.visible });

  renderDropDown() {
    if (!this.state.visible) {
      return null;
    }
    return (
      <div className="languageSelectContainer" ref={this.setWrapperRef}>
        {Object.keys(languages).map(language => (
          <Button.Group
            style={{ display: "flex" }}
            className="languageSelect"
            key={language}
          >
            <LanguageInfoModal
              className={`languageSelectOptionInfo`}
              language={language}
            />
            <Button
              key={language}
              className="languageSelectOption"
              onClick={() => {
                this.props.handleLanguageChange(language);
                this.handleBtnClick();
              }}
            >
              {language}
            </Button>
          </Button.Group>
        ))}
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        <Button
          className="ide-btn"
          onClick={this.handleBtnClick}
          style={{
            minWidth: "150px",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          {this.props.language}
          <Icon type="down" />
        </Button>
        {this.renderDropDown()}
      </React.Fragment>
    );
  }
}

export default LanguageSelect;
