import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import isEmpty from "lodash.isempty";
import Login from "ant-design-pro/lib/Login";

import { Alert, Form, Input, Button, Icon } from "antd";
import { Container, Main, Content } from "./styles";
import { createAccount } from "../../actions";
const { Tab, UserName, Password, Submit } = Login;

class CreateAccount extends React.Component {
  componentDidMount() {
    const { auth } = this.props;
    if (!auth.loading && isEmpty(auth.data)) {
      return this.props.history.push("/login");
    }
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { auth } = this.props;
        this.props.createAccount(auth.data._user._id, values.companyName, () =>
          this.props.history.push("/login")
        );
      }
    });
  };

  render() {
    const { auth } = this.props;
    const { getFieldDecorator } = this.props.form;

    if (auth.loading || isEmpty(auth.data)) {
      return null;
    }
    return (
      <Container>
        <Content>
          <Main>
            <h1>{`Hey ${
              auth.data._user.local.name
            }! It seems that you are not associated with any company.`}</h1>
            <h2>
              Create your own company account and get started with a 7 day free
              trial!
            </h2>
            <Form onSubmit={this.handleSubmit} style={{ margin: "24px 0px" }}>
              <Form.Item>
                {getFieldDecorator("companyName", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your company's name!"
                    }
                  ]
                })(
                  <Input
                    size="large"
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Company Name"
                  />
                )}
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ width: "100%" }}
              >
                Create
              </Button>
            </Form>
            <h2>Or ask your team for an invitation!</h2>
          </Main>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

export default compose(
  Form.create(),
  withRouter,
  connect(
    mapStateToProps,
    { createAccount }
  )
)(CreateAccount);
