import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Row, Col, Card, Alert, Form, Input, Select } from "antd";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { CountryRegionData } from "react-country-region-selector";
import SalesTax from "sales-tax";

import Loading from "../../UI/Loading";
import { createSubscription, updatePaymentSource } from "../../../actions";

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const regionData = country => {
  let regions = [];
  CountryRegionData.forEach(i => {
    if (i[1] === country) {
      regions = i;
    }
  });

  // this could happen if the user is managing the state of the region/country themselves and screws up passing
  // in a valid country
  if (!regions || regions.length === 0) {
    console.error(
      "Error. Unknown country passed: " +
        country +
        '. If you\'re passing a country shortcode, be sure to include countryValueType="short" on the RegionDropdown'
    );
    return [];
  }
  return regions[2].split("|").map(regionPair => {
    let [regionName, regionShortCode = null] = regionPair.split("~");
    return { regionName, regionShortCode };
  });
};

const CollectionCreateForm = Form.create()(
  class extends React.Component {
    state = {
      tax: null,
      country: undefined,
      state: undefined
    };
    async componentDidMount() {
      await SalesTax.setTaxOriginCountry("CA");
    }
    componentDidUpdate(prevProps) {
      const { form } = this.props;
      const { getFieldDecorator, getFieldValue } = form;
      const { country, state, tax } = this.state;
      if (
        getFieldValue("country") &&
        getFieldValue("state") &&
        (getFieldValue("country") !== country ||
          getFieldValue("state") !== state)
      ) {
        SalesTax.getSalesTax(
          getFieldValue("country"),
          getFieldValue("state")
        ).then(tax =>
          this.setState({
            tax,
            country: getFieldValue("country"),
            state: getFieldValue("state")
          })
        );
      }
    }

    render() {
      const {
        form,
        validationError,
        clearError,
        selectedPlan,
        handleSubmit
      } = this.props;
      const { getFieldDecorator, getFieldValue, resetFields } = form;
      const { tax } = this.state;

      return (
        <React.Fragment>
          <Form layout="vertical" style={{ border: "none" }}>
            <Card
              title="Payment Details"
              style={{ width: "560px" }}
              className="subscribe"
            >
              <FormItem label="Address" className="stripe-label">
                {getFieldDecorator("street", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your address!"
                    }
                  ]
                })(
                  <Input
                    type="text"
                    placeholder="185 Berry St."
                    className="full-width"
                  />
                )}
              </FormItem>
              <div style={{ display: "flex" }}>
                <FormItem label="City" className="stripe-label">
                  {getFieldDecorator("city", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your city!"
                      }
                    ]
                  })(
                    <Input
                      type="text"
                      placeholder="San Franscisco"
                      className="half-width"
                    />
                  )}
                </FormItem>
                <FormItem label="Country" className="stripe-label">
                  {getFieldDecorator("country", {
                    rules: [
                      {
                        required: true,
                        message: "Please select your country!"
                      }
                    ]
                  })(
                    <Select
                      placeholder="United States"
                      className="half-width"
                      onSelect={() => {
                        resetFields("state");
                        this.setState({ tax: null });
                      }}
                    >
                      {CountryRegionData.map(country => (
                        <Option key={country[1]}>{country[0]}</Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              </div>
              <div style={{ display: "flex" }}>
                <FormItem label="State/Province" className="stripe-label">
                  {getFieldDecorator("state", {
                    rules: [
                      {
                        required: true,
                        message: "Please select your state/province!"
                      }
                    ]
                  })(
                    <Select placeholder="California" className="half-width">
                      {regionData(getFieldValue("country")).map(region => (
                        <Option key={region.regionShortCode}>
                          {region.regionName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
                <FormItem label="Postal Code" className="stripe-label">
                  {getFieldDecorator("postalCode", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your postal code!"
                      }
                    ]
                  })(
                    <Input
                      type="text"
                      placeholder="94107"
                      className="half-width"
                    />
                  )}
                </FormItem>
              </div>
              <FormItem label="Card" className="stripe-label" required>
                <CardElement
                  hidePostalCode
                  style={{
                    base: {
                      fontSize: "14px",
                      color: "#424770",
                      letterSpacing: "0.025em",
                      fontFamily: "Source Code Pro, monospace",
                      "::placeholder": {
                        color: "#aab7c4"
                      },
                      padding: "10px 14px"
                    },
                    invalid: {
                      color: "#9e2146"
                    }
                  }}
                />
              </FormItem>
              {validationError ? (
                <div style={{ padding: "0 10px 10px 0" }}>
                  <Alert
                    message={validationError}
                    type="error"
                    closable
                    onClose={clearError}
                  />
                </div>
              ) : null}
              <div
                className="ant-col ant-form-item-label"
                style={{ paddingRight: "10px" }}
              >
                <label className="ant-col ant-form-item-label summary">
                  Summary
                </label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "3px"
                  }}
                >
                  <span>{`${selectedPlan.stripe.product} Plan`}</span>
                  <span>{`$${(selectedPlan.stripe.amount / 100).toFixed(
                    2
                  )}`}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "3px"
                  }}
                >
                  <span>{`Taxes @ ${
                    tax && tax.rate ? (tax.rate * 100).toFixed(2) : 0
                  }% ${tax && tax.rate ? tax.type.toUpperCase() : ""}`}</span>
                  <span>{`$${
                    tax
                      ? (
                          (selectedPlan.stripe.amount * tax.rate) /
                          100
                        ).toFixed(2)
                      : (0).toFixed(2)
                  }`}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "3px"
                  }}
                >
                  <span>{`Total`}</span>
                  <span>{`$${
                    tax
                      ? (
                          (selectedPlan.stripe.amount * (1 + tax.rate)) /
                          100
                        ).toFixed(2)
                      : (0).toFixed(2)
                  }`}</span>
                </div>
              </div>
            </Card>

            <button className="stripe-submit" onClick={handleSubmit}>
              {`Pay $${
                tax
                  ? (
                      (selectedPlan.stripe.amount * (1 + tax.rate)) /
                      100
                    ).toFixed(2)
                  : (selectedPlan.stripe.amount / 100).toFixed(2)
              }`}
            </button>
          </Form>
        </React.Fragment>
      );
    }
  }
);

class PaymentContent extends Component {
  state = {
    validationError: ""
  };

  handleUpdate = e => {
    e.preventDefault();

    this.props.stripe
      .createToken({ name: this.props.auth.data._user.local.name })
      .then(({ token }) => {
        console.log({ token });
        if (token) {
          this.props.updatePaymentSource(token, () =>
            this.props.history.push("/settings?tab=billing")
          );
        } else {
          this.setState({
            validationError: "Please enter valid credit card information."
          });
        }
      });
  };
  handleSubmit = e => {
    e.preventDefault();
    const form = this.formRef.props.form;
    form.validateFields(async (err, values) => {
      try {
        if (err) {
          return;
        }
        console.log(values);
        const { street, city, country, state, postalCode } = values;
        const { planName } = this.props;

        await SalesTax.setTaxOriginCountry("CA");
        let tax = await SalesTax.getSalesTax(country, state);
        console.log({ tax });
        let { token } = await this.props.stripe.createToken({
          name: this.props.auth.data._user.local.name
        });

        console.log({ token });
        if (token) {
          let address = {
            street,
            city,
            state,
            country,
            postalCode
          };
          this.props.createSubscription(token, planName, tax, address);
          form.resetFields();
        } else {
          this.setState({
            validationError: "Please enter valid credit card information."
          });
        }
      } catch (error) {
        console.log({ error });
        return;
      }
    });
  };

  clearError = () => {
    this.setState({ validationError: "" });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const { account, plans, planName, type } = this.props;
    const { validationError } = this.state;

    const selectedPlan = plans.data.find(plan => plan.stripe.nickname === planName);

    if (!account.loading) {
      return (
        <Row
          gutter={24}
          style={{
            marginTop: "24px",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Col>
            {type === "new" ? (
              <CollectionCreateForm
                wrappedComponentRef={this.saveFormRef}
                onCancel={this.handleCancel}
                onCreate={this.handleCreate}
                validationError={validationError}
                clearError={this.clearError}
                selectedPlan={selectedPlan}
                handleSubmit={this.handleSubmit}
              />
            ) : (
              <div style={{ minWidth: "500px" }}>
                <div className="stripe-label">
                  <CardElement
                    hidePostalCode
                    style={{
                      base: {
                        fontSize: "14px",
                        color: "#424770",
                        letterSpacing: "0.025em",
                        fontFamily: "Source Code Pro, monospace",
                        "::placeholder": {
                          color: "#aab7c4"
                        },
                        padding: "10px 14px"
                      },
                      invalid: {
                        color: "#9e2146"
                      }
                    }}
                  />
                </div>
                {validationError ? (
                  <div style={{ padding: "0 10px 10px 0" }}>
                    <Alert
                      message={validationError}
                      type="error"
                      closable
                      onClose={this.clearError}
                    />
                  </div>
                ) : null}
                <button className="stripe-submit" onClick={this.handleUpdate}>
                  {`Update payment method`}
                </button>
              </div>
            )}
          </Col>
        </Row>
      );
    } else return <Loading height={"50vh"} />;
  }
}

const mapStateToProps = ({ auth, account, plans }) => ({
  auth,
  account,
  plans
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      createSubscription,
      updatePaymentSource
    }
  )
)(injectStripe(PaymentContent));
