import React from "react";

const displayTime = time =>
  Number(time) > 1
    ? `(finished in ${Number(time)}s)`
    : `(finished in ${Number(time) * 1000}ms)`;

export const generateCodeRoomTerminalLines = (terminal, participants, colors) =>
  terminal.reduce(
    (acc, curr) => {
      let { user, lines, language, time, type, stdouts } = curr;
      let colorIndex = participants.findIndex(i => user && i.id === user.id);
      let info = (
        <div>
          {user ? <span
            style={{ color: colorIndex > -1 ? colors[colorIndex] : "white" }}
          >
            {`${user.username} `}
          </span> : null}
          <span>{`ran ${lines} line${lines > 1 ? "s" : ""} of ${language}${
            time ? ` ${displayTime(time)}` : ""
          }:`}</span>
        </div>
      );

      if (type === "success") {
        return [...acc, info, ...stdouts[0].split("\n"), ">"];
      }
      if (type === "error") {
        return stdouts.length
          ? [
              ...acc,
              info,
              ...stdouts[0].split("\n"),
              ...curr.data.split("\n"),
              ">"
            ]
          : [...acc, info, ...curr.data.split("\n"), ">"];
      }
    },
    [">"]
  );

export const generateCodeChallengeTerminalLines = terminal =>
  terminal.reduce(
    (acc, curr) => {
      let { lines, language, time, type, stdouts } = curr;
      let info = (
        <div>
          <span>{`Ran ${lines} line${lines > 1 ? "s" : ""} of ${language}${
            time ? ` ${displayTime(time)}` : ""
          }:`}</span>
        </div>
      );

      if (type === "success") {
        return [...acc, info, ...stdouts[0].split("\n"), ">"];
      }
      if (type === "error") {
        return stdouts.length
          ? [
              ...acc,
              info,
              ...stdouts[0].split("\n"),
              ...curr.data.split("\n"),
              ">"
            ]
          : [...acc, info, ...curr.data.split("\n"), ">"];
      }
    },
    [">"]
  );
