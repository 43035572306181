import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";

import { Redirect } from "react-router";

import { fetchUser, fetchCompilers } from "./actions";

import { Layout, Spin, Icon } from "antd";
import GlobalFooter from "ant-design-pro/lib/GlobalFooter";

import SiderMenu from "./components/SiderMenu";
import GlobalHeader from "./components/GlobalHeader";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Invitation from "./components/Auth/Invitation";
import CreateAccount from "./components/Auth/CreateAccount";

import Interviews from "./components/Interviews";
import Questions from "./components/Questions";

import Editor from "./components/Editor";
import ThankYou from "./components/ThankYou";
import Playback from "./components/Editor/Playback";

import Settings from "./components/Settings";
import Payment from "./components/Settings/Billing/Payment";
import Plans from "./components/Settings/Billing/Plans";
import Integrations from "./components/Integrations";

import ChallengeScreen from "./components/ChallengeScreen";

import TopMenu from "./components/TopMenu";
import Tour from "./components/Tour";

import NotFound from "./components/UI/NotFound";

import { setUserEmail, setUserName } from "./chat.js";

const { Content, Header, Footer } = Layout;
const menuKeyStates = {
  "/integrations": { topMenuKey: "1", sideMenuKey: "1" },
  "/settings": { topMenuKey: "1", sideMenuKey: "2" },
  "/settings/plans": { topMenuKey: "1", sideMenuKey: "2" },
  "/settings/payment": { topMenuKey: "1", sideMenuKey: "2" },
  "/coderoom/interviews": { topMenuKey: "1", sideMenuKey: "3" },
  "/coderoom/questions": { topMenuKey: "1", sideMenuKey: "4" },
  "/codechallenge/interviews": { topMenuKey: "2", sideMenuKey: "5" },
  "/codechallenge/questions": { topMenuKey: "2", sideMenuKey: "6" }
};

class App extends Component {
  state = {
    collapsed: false,
    topMenuKey: "1",
    sideMenuKey: "3",
    runTour: true,
    stepIndex: 0
  };

  componentDidMount() {
    const { location } = this.props;
    this.props.fetchUser();
    if (
      location.pathname &&
      location.pathname !== "/login" &&
      location.pathname !== "/register" &&
      location.pathname !== "/account"
    ) {
      this.props.fetchCompilers();
      if (menuKeyStates[this.props.location.pathname]) {
        const { topMenuKey, sideMenuKey } = menuKeyStates[
          this.props.location.pathname
        ];
        this.setState({ topMenuKey, sideMenuKey });
      }
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      !isEqual(this.props.auth.data, prevProps.auth.data) &&
      !isEmpty(this.props.auth.data)
    ) {
      setUserEmail(window, this.props.auth.data._user.local.email);
      setUserName(window, this.props.auth.data._user.local.name);
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  changeMenuKey = (type, key) => {
    if (type === "top") {
      return this.setState({ topMenuKey: key });
    }
    if (type === "side") {
      return this.setState({ sideMenuKey: key });
    }
  };

  tourMethod = type => {
    if (type === "NEXT") {
      this.setState({ stepIndex: this.state.stepIndex++ });
    } else if (type === "STOP") {
      this.setState({ runTour: false });
    } else if (type === "START") {
      this.setState({ runTour: true });
    }
  };

  renderTour() {
    const { runTour, stepIndex } = this.state;

    return <Tour run={runTour} stepIndex={stepIndex} />;
  }

  renderLoading() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            height: "100vh"
          }}
        >
          <Spin size="large" />
        </div>
      </React.Fragment>
    );
  }

  renderMain() {
    const { auth } = this.props;

    if (!isEmpty(auth.data)) {
      return (
        <Layout>
          <TopMenu
            menuKey={this.state.topMenuKey}
            changeMenuKey={this.changeMenuKey}
          />
          <Header
            style={{
              background: "#fff",
              padding: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <GlobalHeader
              currentUser={{
                avatar:
                  "https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png",
                name: auth.data._user.local.name
              }}
              collapsed={this.state.collapsed}
              onCollapse={this.toggle}
              onMenuClick={() => {}}
            />
          </Header>
          <Content style={{ margin: "24px 24px 0", height: "100%" }}>
            <Switch>
              <Route
                exact
                path="/coderoom/interviews"
                component={() => (
                  <Interviews
                    type="CodeRoom"
                    // tourMethod={this.tourMethod}
                    // tour={() => this.renderTour()}
                  />
                )}
              />
              <Route
                exact
                path="/coderoom/questions"
                component={() => <Questions type="CodeRoom" />}
              />
              <Route
                exact
                path="/codechallenge/interviews"
                component={() => <Interviews type="CodeChallenge" />}
              />
              <Route
                exact
                path="/codechallenge/questions"
                component={() => <Questions type="CodeChallenge" />}
              />

              <Route exact path="/integrations" component={Integrations} />
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/settings/payment" component={Payment} />
              <Route exact path="/settings/plans" component={Plans} />
              <Route component={NotFound} />
            </Switch>
          </Content>
        </Layout>
      );
    }
    return null;
  }

  render() {
    const { auth } = this.props;
    return (
      <React.Fragment>
        {/* <Tour /> */}
        <Switch>
          <Redirect exact from="/" to="/coderoom/interviews" />
          <Route exact path="/room/:_id" component={Editor} />
          <Route exact path="/room/:_id/playback" component={Playback} />
          <Route exact path="/room/:_id/thanks" component={ThankYou} />
          <Route exact path="/challenge/:_id" component={ChallengeScreen} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/account" component={CreateAccount} />
          <Route exact path="/invitation/:token" component={Invitation} />
          {auth.error && !auth.loading ? (
            <Redirect to="/register" />
          ) : auth.loading ? (
            this.renderLoading()
          ) : !auth.loading &&
            !isEmpty(auth.data) &&
            auth.data.message === "No associated memberships!" ? (
            <Redirect to="/account" />
          ) : (
            <React.Fragment>
              <Layout>
                <SiderMenu
                  trigger={null}
                  collapsible
                  collapsed={this.state.collapsed}
                  menuKey={this.state.sideMenuKey}
                  topMenuKey={this.state.topMenuKey}
                  changeMenuKey={this.changeMenuKey}
                  onCollapse={this.toggle}
                />

                {this.renderMain()}
              </Layout>
            </React.Fragment>
          )}
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
});
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { fetchUser, fetchCompilers }
  )
)(App);
