import React, { Component } from "react";
import Login from "ant-design-pro/lib/Login";
import { Alert } from "antd";
import { Link } from "react-router-dom";
import {
  loginUser,
  signUpUser,
  clearAuthState,
  createTeamMember
} from "../../actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { Redirect, withRouter } from "react-router";
import isEqual from "lodash.isequal";

import { Container, Main, Content } from "./styles";
import Loading from "../UI/Loading";
const { Tab, UserName, Password, Submit } = Login;

class AuthForm extends Component {
  state = {
    notice: "",
    type: "tab1",
    error: null
  };

  componentDidMount() {
    this.props.clearAuthState();
  }
  componentDidUpdate(prevProps) {
    const { auth } = this.props;
    // Typical usage (don't forget to compare props):
    if (!isEqual(auth, prevProps.auth) && auth.error) {
      this.setState({ error: auth.error });
    }
  }
  onSubmit = (err, values) => {
    const { type, invite } = this.props;

    if (!err) {
      this.setState({ notice: "" }, () => {
        let { email, password, name, companyName } = values;
        if (type === "login") {
          return this.props.loginUser(email, password);
        } else if (type === "invitation") {
          let _invite = invite.data._id;
          let _account = invite.data._account;
          return this.props.createTeamMember(
            _invite,
            email,
            password,
            name,
            _account,
            () => this.props.history.push("/login")
          );
        } else {
          return this.props.signUpUser(email, password, name, companyName);
        }
      });
    }
  };

  renderMessage = content => {
    return (
      <Alert
        style={{ marginBottom: 24 }}
        message={content}
        type="error"
        showIcon
      />
    );
  };

  render() {
    const { type, auth, invite } = this.props;
    const { error } = this.state;
    console.log(this.props);
    if (
      auth &&
      auth.data &&
      auth.data._user &&
      auth.data._user.local &&
      type !== "invitation"
    ) {
      return <Redirect to="/coderoom/interviews" />;
    }
    if (auth.loading) {
      return <Loading height={"100vh"}/>;
    }
    return (
      <Container>
        <Content>
          <Main>
            <Login defaultActiveKey={this.state.type} onSubmit={this.onSubmit}>
              <Tab key="tab1" tab="">
                {error ? this.renderMessage(error.message) : null}
                {type === "login" || type === "invitation" ? null : (
                  <UserName
                    name="companyName"
                    placeholder="Company Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your company's name!"
                      }
                    ]}
                  />
                )}
                {type === "login" ||
                (type === "invitation" && invite.data.existingUser) ? null : (
                  <UserName
                    name="name"
                    placeholder="Full Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your full name!"
                      }
                    ]}
                  />
                )}
                {type === "invitation" ? (
                  <UserName
                    name="email"
                    placeholder="Email"
                    defaultValue={invite.data.email}
                    disabled
                  />
                ) : (
                  <UserName
                    name="email"
                    placeholder="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email address!"
                      }
                    ]}
                  />
                )}

                <Password
                  name="password"
                  placeholder="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a password!"
                    }
                  ]}
                />
              </Tab>
              <Submit>
                {type === "login" ||
                (type === "invitation" && invite.data.existingUser)
                  ? "Login"
                  : "Register"}
              </Submit>
              {type === "invitation" ? null : (
                <div>
                  <Link
                    to={type === "login" ? "/register" : "/login"}
                    style={{ float: "right" }}
                  >
                    {type === "login" ? "Register" : "Login"}
                  </Link>
                </div>
              )}
            </Login>
          </Main>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, invite }) => ({ auth, invite });

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { loginUser, signUpUser, clearAuthState, createTeamMember }
  )
)(AuthForm);
