import React from "react";
import { Button, Form, Icon, Drawer, InputNumber } from "antd";
import { connect } from "react-redux";

import { createInterview, fetchQuestions, nextTourStep } from "../../actions";
import QuestionsModal from "./QuestionsModal";

const CollectionCreateForm = Form.create()(
  class extends React.Component {
    remove = id => {
      const { form } = this.props;
      // can use data-binding to get
      const questions = form.getFieldValue("selectedQuestions");
      // We need at least one passenger
      if (questions.length === 1) {
        return;
      }

      // can use data-binding to set
      form.setFieldsValue({
        selectedQuestions: questions.filter(q => q._id !== id)
      });
    };

    add = data => {
      console.log({ data });
      const { form } = this.props;
      // can use data-binding to get
      const questions = form.getFieldValue("selectedQuestions");
      console.log(questions);
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        selectedQuestions: questions.concat(data)
      });
    };

    render() {
      const { visible, onCancel, onCreate, form, questions, type } = this.props;
      const { getFieldDecorator, getFieldValue } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 }
        }
      };
      const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 }
        }
      };
      getFieldDecorator("selectedQuestions", { initialValue: [] });
      const selectedQuestions = getFieldValue("selectedQuestions");
      const formItems = selectedQuestions.map((q, index) => (
        <Form.Item
          {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
          label={index === 0 ? "Questions" : ""}
          required={false}
          key={q._id}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>{q.title}</div>
            {selectedQuestions.length > 1 ? (
              <Icon
                className="dynamic-delete-button"
                type="minus-circle-o"
                onClick={() => this.remove(q._id)}
              />
            ) : null}
          </div>
        </Form.Item>
      ));
      return (
        <Drawer
          title="Create a CodeChallenge"
          width={720}
          onClose={onCancel}
          visible={visible}
        >
          <Form layout="vertical" hideRequiredMark>
            {formItems}

            <Form.Item
              {...formItemLayoutWithOutLabel}
              label={selectedQuestions.length === 0 ? "Questions" : ""}
            >
              {getFieldDecorator("selectedQuestions", {
                rules: [
                  {
                    required: true,
                    message: "Please select CodeChallenge questions!"
                  }
                ]
              })(
                <QuestionsModal
                  type={type}
                  addSelectedQuestion={this.add}
                  selectedQuestions={selectedQuestions}
                  questions={questions}
                />
              )}
            </Form.Item>
            <Form.Item {...formItemLayoutWithOutLabel} label="Time">
              {getFieldDecorator("time", {
                rules: [
                  {
                    required: true,
                    message:
                      "Please input how much time should be given to complete the CodeChallenge!"
                  }
                ]
              })(
                <div>
                  <span>
                    <InputNumber min={0} />
                  </span>
                  <span style={{ marginLeft: "10px" }}>minutes</span>
                </div>
              )}
            </Form.Item>
          </Form>
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right"
            }}
          >
            <Button onClick={onCancel} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={onCreate} type="primary">
              Create
            </Button>
          </div>
        </Drawer>
      );
    }
  }
);

class AddInterview extends React.Component {
  state = {
    visible: false
  };

  handleClick = () => {
    const { type } = this.props;

    if (type === "CodeRoom") {
      return this.props.createInterview(type, {}, () =>
        this.props.nextTourStep()
      );
    }
    if (type === "CodeChallenge") {
      this.props.fetchQuestions(type);
      return this.setState({ visible: true });
    }
  };

  handleCancel = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      console.log(values);
      let data = {
        selectedQuestions: values.selectedQuestions.map(q => q._id),
        time: parseInt(values.time, 10)
      };
      this.props.createInterview(this.props.type, data);
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  renderButtonToModal() {
    return (
      <div>
        <Button
          type="primary"
          onClick={this.handleClick}
          className="schedule-new-interview"
        >
          Schedule New Interview
        </Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          questions={this.props.questions}
          type={this.props.type}
        />
      </div>
    );
  }
  render() {
    // const { keywords, subscription, auth, account } = this.props;
    // let daysLeft = // account trial days + ( negative number in days )
    //   account.data.max_trial_days +
    //   Math.ceil(
    //     (new Date(account.data.createdAt) - new Date()) / 1000 / 60 / 60 / 24
    //   );
    // if (isEmpty(subscription.data)) {
    //   // no plan
    //   if (
    //     daysLeft >= 0 &&
    //     keywords.data.length < account.data.max_tracked_words
    //   ) {
    //     // still in trial AND keywords less than max plan
    //     return this.renderButtonToModal();
    //   } else {
    //     return <ExceededLimits />;
    //   }
    // } else if (
    //   !isEmpty(subscription.data) &&
    //   keywords.data.length >= account.data.max_tracked_words
    // ) {
    //   return <ExceededLimits />;
    // } else {
    //   return this.renderButtonToModal();
    // }
    return this.renderButtonToModal();
  }
}

const mapStateToProps = ({ auth, account, questions }) => ({
  auth,
  account,
  questions
});

export default connect(
  mapStateToProps,
  { createInterview, fetchQuestions, nextTourStep }
)(AddInterview);
