import React from "react";
import { Button, Modal, Form, Select } from "antd";
import { connect } from "react-redux";

import { updateTeamMember } from "../../actions";

const Option = Select.Option;

const CollectionCreateForm = Form.create()(
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form, record } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Edit Access"
          okText="Save"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <Form.Item label="Select Access" hasFeedback>
              {getFieldDecorator("access", {
                rules: [
                  { required: true, message: "Please select an access role!" }
                ],
                initialValue: record.access
              })(
                <Select placeholder="Access Role">
                  <Option value="ADMIN">Admin</Option>
                  <Option value="MEMBER">Member</Option>
                </Select>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class EditTeamMember extends React.Component {
  state = {
    visible: false
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const { _id, status } = this.props.record;

      let type = status === "active" ? "membership" : "invite";
      this.props.updateTeamMember(_id, type, values.access);
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    return (
      <React.Fragment>
        <Button onClick={this.showModal}>Edit</Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          record={this.props.record}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  { updateTeamMember }
)(EditTeamMember);
