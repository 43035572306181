export const hideChat = window => {
  return window.$crisp.push(["do", "chat:hide"]);
};

export const showChat = window => window.$crisp.push(["do", "chat:show"]);

export const initializeChat = () => {
  // Include the Crisp code here, without the <script></script> tags
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = "1492820a-3a73-4687-a457-e6656ac252ef";

  (function() {
    var d = document;
    var s = d.createElement("script");

    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
};

export const setUserEmail = (window, email) => {
  return window.$crisp.push(["set", "user:email", [email]]);
};

export const setUserName = (window, name) => {
  return window.$crisp.push(["set", "user:nickname", [name]]);
};
