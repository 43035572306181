import React, { Component } from "react";
import {
  Row,
  Col,
  Icon,
  Table,
  Divider,
  Card,
  Input,
  Dropdown,
  Menu,
  Button,
  Popconfirm
} from "antd";
import PageHeader from "ant-design-pro/lib/PageHeader";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import isEmpty from "lodash.isempty";
import moment from "moment";

import {
  fetchInterviews,
  deleteInterview,
  createInterview,
  fetchTeamMembers,
  endTour
} from "../../actions";
import AddInterview from "./AddInterview";
import EditInterview from "./EditInterview";
import Loading from "../UI/Loading";
import Tour from "../Tour";
import { showChat } from "../../chat";

const { Search } = Input;

class Interviews extends Component {
  state = {
    copySuccess: false
  };

  componentDidMount() {
    const { type } = this.props;
    this.props.fetchInterviews(type);
    this.props.fetchTeamMembers();
    showChat(window);
  }

  render() {
    const { teamMembers, interviews, type } = this.props;
    const columns = [
      {
        title: "RoomId",
        key: "roomId",
        render: (text, record) => <div>{record.roomId}</div>
      },
      {
        title: "Created",
        key: "createdAt",
        render: (text, record) => (
          <div>{moment(record.createdAt).fromNow()}</div>
        )
      },
      {
        title: "Creator",
        key: "creator",
        render: (text, record) => {
          let data = teamMembers.data.find(
            member => member._id === record.creator
          );

          return (
            <div>{data ? <div key={data._id}>{data.name}</div> : null}</div>
          );
        }
      },
      {
        title: "Status",
        key: "status",
        render: (text, record) => {
          if (record.status === "COMPLETED") {
            return <div>Ended</div>;
          } else {
            return <div>Upcoming</div>;
          }
        }
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => {
          console.log({ record });
          if (type === "CodeRoom") {
            return (
              <span>
                {record.status === "COMPLETED" ? (
                  // <a href={`/room/${record.roomId}/playback`}>
                  //   <Button>Playback</Button>
                  // </a>
                  <Link to={`/room/${record.roomId}/playback`}>
                    <Button>Playback</Button>
                  </Link>
                ) : (
                  <Link to={`/room/${record.roomId}`}>
                    <Button className="start">Start</Button>
                  </Link>
                  // <a
                  //   href={`/room/${record.roomId}`}
                  //   target={"_blank"}
                  //   onClick={this.props.endTour}
                  // >
                  //   <Button className="start">Start</Button>
                  // </a>
                )}
              </span>
            );
          } else {
            return (
              <span>
                {record.status === "COMPLETED" ? (
                  <a href={`/challenge/${record.roomId}/results`}>
                    <Button>View Results</Button>
                  </a>
                ) : (
                  <Button
                    onClick={async () => {
                      await navigator.clipboard.writeText(
                        `${process.env.REACT_APP_CLIENT_BASE_URL}/challenge/${
                          record.roomId
                        }`
                      );
                      this.setState({ copySuccess: true });
                    }}
                    style={
                      this.state.copySuccess
                        ? {
                            borderColor: "#389e0d",
                            color: "#389e0d"
                          }
                        : {}
                    }
                  >
                    Copy Link
                    {this.state.copySuccess ? (
                      <Icon type="check-circle" />
                    ) : null}
                  </Button>
                )}
              </span>
            );
          }
        }
      }
    ];

    if (isEmpty(teamMembers.data)) {
      return <Loading height={"50vh"} />;
    } else {
      return (
        <React.Fragment>
          <Tour />
          {/* {this.props.tour} */}
          <div style={{ margin: "-24px -24px 0" }}>
            <PageHeader
              key="pageheader"
              title={`${type} Interviews`}
              action={
                // <Button type="primary" onClick={() => this.props.createInterview(type)}>
                //   {`Schedule ${type} Interview`}
                // </Button>
                <AddInterview type={type} />
              }
            />
          </div>

          <Row
            gutter={24}
            style={{ margin: "24px auto 0px auto", maxWidth: "1000px" }}
          >
            <Col span={24}>
              <Card>
                <Table
                  columns={columns}
                  dataSource={interviews.data}
                  showHeader={true}
                  rowKey={record => record._id}
                />
              </Card>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = ({ interviews, teamMembers }) => ({
  interviews,
  teamMembers
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      fetchInterviews,
      deleteInterview,
      createInterview,
      fetchTeamMembers,
      endTour
    }
  )
)(Interviews);
