import React from "react";

export const DASHBOARD_INTERVIEWS_STEPS = [
  {
    target: ".schedule-new-interview",
    title: "Wait, don't skip this!",
    content: (
      <div>
        <p>
          You'll learn the core concepts of CoderTest{" "}
          <strong>in 2 minutes</strong>.
        </p>
        <p>Let's start by creating a new interview.</p>
        <div className="onboarding-step-bubble">
          <strong>Click the button above.</strong>
        </div>
      </div>
    ),
    link: "/coderoom/interviews"
  },
  {
    target: ".start:nth-of-type(1)",
    title: "Start the interview!",
    content: (
      <div>
        <p>Nice! Now let's go to the CodeRoom interview.</p>
        <div className="onboarding-step-bubble">
          <strong>Click the button above.</strong>
        </div>
      </div>
    ),

    link: "/coderoom/interviews"
  }
];

export const CODE_ROOM_INTERVIEW_STEPS = [
  {
    target: ".solution-editor",
    title: "Write your solution!",
    content: (
      <div>
        <p>
          There is a boilerplate <strong>hello</strong> function in{" "}
          <strong>JavaScript</strong>.
        </p>
        <p>
          Let's edit this solution file to call the{" "}
          <strong>hello function</strong> and print the result in the console.
        </p>
        <div className="onboarding-step-bubble">
          <p>
            <strong>In line 6, type the following: </strong>
          </p>
          <code>console.log(hello(2))</code>
        </div>
      </div>
    ),
    placement: "right",
    spotlightPadding: 0,
    link: "/room/:_id"
  },
  {
    target: ".ant-btn.run-btn.ant-btn-primary",
    title: "Run your solution!",
    content: (
      <div>
        <p>Compile and run the solution file.</p>
        <div className="onboarding-step-bubble">
          <strong>Click Run.</strong>
        </div>
      </div>
    ),
    placement: "right",
    spotlightPadding: 0,
    link: "/room/:_id"
  },
  {
    target: ".terminal-output",
    title: "Results!",
    content: (
      <div>
        <p>View the result from running the solution file.</p>
      </div>
    ),
    placement: "left",
    spotlightPadding: 0,
    link: "/room/:_id"
  },
  {
    target:
      ".ant-tabs.ant-tabs-top.editor-files.ant-tabs-line .ant-tabs-bar.ant-tabs-top-bar",
    title: "Switch to your test file!",
    content: (
      <div>
        <p>
          Implement TDD or BDD during your interview with a separate test file.
        </p>
        <div className="onboarding-step-bubble">
          <strong>Click on test.js</strong>
        </div>
      </div>
    ),
    placement: "right",
    spotlightPadding: 0,
    link: "/room/:_id"
  },
  {
    target: ".test-editor",
    title: "Write your tests!",
    content: (
      <div>
        <p>
          There is some boilerplate tests using the{" "}
          <strong>Mocha library</strong>.
        </p>
        <p>
          Let's edit this test file and write a new test for the case where n=0.
        </p>
        <div className="onboarding-step-bubble">
          <p>
            <strong>In line 15, type the following: </strong>
          </p>
          <pre>
            {`describe("hello(0)", function() {
  it('should return []', function() {
    assert.deepEqual(hello(0), []);
  });
});`}
          </pre>
        </div>
      </div>
    ),
    placement: "right",
    spotlightPadding: 0,
    link: "/room/:_id"
  },
  {
    target: ".ant-btn.run-btn.ant-btn-primary",
    title: "Run your tests!",
    content: (
      <div>
        <p>Compile and run the test file.</p>
        <div className="onboarding-step-bubble">
          <strong>Click Run.</strong>
        </div>
      </div>
    ),
    placement: "right",
    spotlightPadding: 0,
    link: "/room/:_id"
  },
  {
    target: ".terminal-output",
    title: "Results!",
    content: (
      <div>
        <p>View the result from running the test file.</p>
      </div>
    ),
    placement: "left",
    spotlightPadding: 0,
    link: "/room/:_id"
  },
  {
    target: ".language-select-and-info",
    title: "More Languages!",
    content: (
      <div>
        <p>
          We have support for over 14 languages. And we have installed popular
          libraries and frameworks for your convenience.
        </p>
        <div className="onboarding-step-bubble">
          <p>
            <strong>Click Info</strong> for more information about our
            JavaScript environment
          </p>
          <p>
            <strong>Or use the dropdown</strong> to select another language!
          </p>
        </div>
      </div>
    ),

    placement: "bottom",
    spotlightPadding: 0,
    link: "/room/:_id"
  },
  {
    target: ".ant-btn.ide-btn.end-interview",
    title: "End your interview!",
    content: (
      <div>
        <p>OK! We have covered the core concepts of the CodeRoom interview.</p>
        <p>Let's end the interview and review our interview.</p>
        <div className="onboarding-step-bubble">
          <p>
            <strong>Click End Interview</strong> and then{" "}
            <strong>confirm</strong> that you want to end the interview in the
            popup.
          </p>
        </div>
      </div>
    ),
    placement: "top",
    spotlightPadding: 0,
    link: "/room/:_id"
  }
];

export const PLAYBACK_CODE_ROOM_INTERVIEW_STEPS = [
  {
    target: ".playback-button-icon",
    title: "Replay!",
    content: (
      <div>
        <p>Let's review and replay our interview.</p>
        <div className="onboarding-step-bubble">
          <p>
            <strong>Click the play button below</strong> to start the interview
            playback.
          </p>
        </div>
      </div>
    ),
    placement: "top",
    spotlightPadding: 0,
    link: "/room/:_id/playback"
  },
  {
    target: ".ide-btn.back-to-dashboard",
    title: "We're Done!",
    content: (
      <div>
        <p>Let's go back to our dashboard and start interviewing candidates!</p>
        <div className="onboarding-step-bubble">
          <p>
            <strong>Click the button below</strong> go back to the dashboard!
          </p>
        </div>
      </div>
    ),
    placement: "top",
    spotlightPadding: 0,
    link: "/room/:_id/playback"
  }
];

export const steps = [
  ...DASHBOARD_INTERVIEWS_STEPS,
  ...CODE_ROOM_INTERVIEW_STEPS,
  ...PLAYBACK_CODE_ROOM_INTERVIEW_STEPS
];
