import React from "react";
import Markdown from "markdown-to-jsx";

const Container = ({ children, ...props }) => (
  <div style={{ marginBottom: "1em" }} {...props}>
    {children}
  </div>
);

const MarkdownJSX = ({ children, options = { overrides: {} }, ...props }) => {
  options.overrides["Container"] = {
    component: Container
  };
  return <Markdown children={children} {...props} options={options} />;
};

export default MarkdownJSX;
