import React from "react";
import { Popover, Button, Modal, Form, DatePicker, Select, Input } from "antd";
import { connect } from "react-redux";

import { createQuestion, updateQuestion } from "../../actions";
import Link from "react-router-dom/Link";

import { languages } from "../../constants";

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const CollectionCreateForm = Form.create()(
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form, type, record } = this.props;
      const { getFieldDecorator, getFieldValue } = form;
      return (
        <Modal
          visible={visible}
          title={type === "add" ? "Write New Question" : "Edit Question"}
          okText="Save"
          onCancel={onCancel}
          onOk={onCreate}
          width="40vw"
        >
          <Form layout="vertical">
            <FormItem label="Title">
              {getFieldDecorator("title", {
                initialValue: type === "edit" ? record.title : "",
                rules: [
                  {
                    required: true,
                    message: "Please input the title of the question!"
                  }
                ]
              })(<Input placeholder="Please input the title" />)}
            </FormItem>
            <FormItem label="Language">
              {getFieldDecorator("language", {
                initialValue: type === "edit" ? record.language : "",
                rules: [
                  {
                    required: true,
                    message: "Please select a language!"
                  }
                ]
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Please select a language"
                >
                  {Object.keys(languages).map(language => (
                    <Option key={language}>{language}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem label="Description">
              <p>
                Notes about the question and answers for yourself and
                colleagues.
              </p>
              {getFieldDecorator("description", {
                initialValue: type === "edit" ? record.description : ""
              })(
                <TextArea
                  placeholder="Please input the description"
                  autosize={{ minRows: 4, maxRows: 8 }}
                />
              )}
            </FormItem>
            <FormItem label="Question">
              <p>
                ​
                {`This text will be inserted into the ${
                  getFieldValue("language") &&
                  languages[getFieldValue("language")]
                    ? languages[getFieldValue("language")].solution.sourceFile
                    : "Solution"
                } file of your
                interview if you select this question.`}
              </p>
              {getFieldDecorator("questionSolution", {
                initialValue: type === "edit" ? record.question.solution : "",
                rules: [
                  {
                    required: true,
                    message: "Question cannot be blank!"
                  }
                ]
              })(
                <TextArea
                  placeholder="Please input the question"
                  autosize={{ minRows: 4, maxRows: 8 }}
                />
              )}
            </FormItem>
            {getFieldValue("language") &&
            getFieldValue("language") !== "HTML/CSS/JS" &&
            languages[getFieldValue("language")].test.testable ? (
              <FormItem label="Tests">
                <p>
                  ​
                  {`This text will be inserted into the ${
                    getFieldValue("language") &&
                    languages[getFieldValue("language")]
                      ? languages[getFieldValue("language")].test.sourceFile
                      : "Tests"
                  } file of your
                  interview if you select this question.`}
                </p>
                {getFieldDecorator("questionTest", {
                  initialValue: type === "edit" ? record.question.test : ""
                })(
                  <TextArea
                    placeholder="Please input any tests"
                    autosize={{ minRows: 4, maxRows: 8 }}
                  />
                )}
              </FormItem>
            ) : null}
          </Form>
        </Modal>
      );
    }
  }
);

class QuestionModal extends React.Component {
  state = {
    visible: false
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      console.log(values);
      const {
        language,
        questionSolution,
        questionTest,
        title,
        description
      } = values;
      const { type } = this.props;
      let question = { solution: questionSolution, test: questionTest };
      if (type === "add") {
        this.props.createQuestion(language, question, title, description);
      } else {
        this.props.updateQuestion(
          this.props.record._id,
          language,
          question,
          title,
          description
        );
      }
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  renderAddButton() {
    return (
      <Button type="primary" onClick={this.showModal}>
        Write New Question
      </Button>
    );
  }

  renderEditButton() {
    return <Button onClick={this.showModal}>Edit</Button>;
  }
  render() {
    const { type, record } = this.props;
    console.log({ type, record });

    return (
      <div>
        {type === "add" ? this.renderAddButton() : this.renderEditButton()}
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          type={type}
          record={record}
        />
      </div>
    );
  }
}

export default connect(
  null,
  { createQuestion, updateQuestion }
)(QuestionModal);
