import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import isEmpty from "lodash.isempty";
import { Button, Icon, Tabs, Slider, Spin } from "antd";
import moment from "moment";

import CodeEditor from "./CodeMirrorEditor";
import Terminal from "./Terminal";
import MarkdownNotes from "./MarkdownNotes";
import Tour from "../Tour";
import SocketContext from "../../socket-context";
import { socketTypes, languages } from "../../constants";

import "./styles.css";
import { generateCodeRoomTerminalLines } from "./utils";
import { hideChat } from "../../chat.js";

const { TabPane } = Tabs;

const {
  ENTER_CODE_ROOM_PLAYBACK,
  SAVE_CODE_ROOM_INTERVIEWER_NOTES
} = socketTypes;

const colors = [
  "#f5222d",
  "#1890ff",
  "#52c41a",
  "#fa8c16",
  "#faad14",
  "#722ed1",
  "#eb2f96",
  "#fa541c"
];

class Playback extends Component {
  state = {
    solution_code: "",
    test_code: "",
    terminal: [],
    participants: [],
    language: "JavaScript",
    socketId: "",
    markdownNotes: "",
    play: false,
    sliderLength: 0,
    sliderState: 0,
    interview: null
  };

  onMarkdownEditorChange = (editor, data, value) =>
    this.setState(
      {
        markdownNotes: value
      },
      () =>
        this.props.socket.emit(SAVE_CODE_ROOM_INTERVIEWER_NOTES, {
          notes: value
        })
    );

  componentDidMount = () => {
    const room = this.props.match.params._id;
    const { socket } = this.props;
    this.interval = null;
    console.log(this.props);

    hideChat(window);

    if (socket.connected) {
      socket.emit(ENTER_CODE_ROOM_PLAYBACK, room);
      console.log("connected to room: ", room);
    }

    socket.on("connect", () => {
      console.log("connected: ", socket.id);
      socket.emit(ENTER_CODE_ROOM_PLAYBACK, room);
      console.log("connected to room: ", room);
    });
    socket.on(ENTER_CODE_ROOM_PLAYBACK, data => {
      console.log({ data });
      const { interview } = data;
      let length = interview.snapshots.length - 1;
      this.setState({
        interview,
        sliderLength: length,
        sliderState: length,
        markdownNotes: data.interview.notes
      });
    });
  };
  
  onSliderChange = value => {
    console.log("onChange: ", value, this.state.sliderState);
    this.setState({ sliderState: value });
  };

  tick() {
    const { sliderLength, sliderState } = this.state;
    if (sliderLength > 0 && sliderLength === sliderState) {
      return this.setState({ sliderState: 0 });
    } else if (sliderLength - 1 === sliderState) {
      this.setState({ sliderState: sliderState + 1, play: false }, () =>
        clearInterval(this.interval)
      );
    } else if (sliderLength === 0 && sliderLength === sliderState) {
      this.setState({ play: false }, () => clearInterval(this.interval));
    } else {
      return this.setState({
        sliderState: sliderState + 1
      });
    }
  }

  onClickPlay = () => {
    this.setState({ play: true });
    this.interval = setInterval(() => this.tick(), 300);
  };

  onClickPause = () => {
    clearInterval(this.interval);
    this.setState({ play: false });
  };

  render() {
    const {
      participants,
      markdownNotes,
      play,
      sliderLength,
      sliderState,
      interview
    } = this.state;
    const { auth } = this.props;
    if (auth.loading || isEmpty(auth.data) || interview === null) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh"
          }}
        >
          <Spin size="large" />
        </div>
      );
    }
    let {
      language,
      solution_code,
      test_code,
      output,
      createdAt,
      activeFile
    } = interview.snapshots[sliderState];
    return (
      <React.Fragment>
        <Tour />

        <div
          style={{ height: "100vh", backgroundColor: "rgba(23, 27, 33, 0.96)" }}
        >
          <div
            style={{
              display: "flex"
            }}
          >
            <div style={{ width: "50%", padding: "8px 4px 8px 8px" }}>
              <Tabs
                activeKey={activeFile}
                onChange={() => {}}
                tabBarStyle={{
                  background: "#181C22",
                  margin: 0,
                  padding: "0 10px 16px",
                  border: "none"
                }}
                className="editor-files"
              >
                <TabPane
                  tab={
                    <div className="editor-file">
                      {languages[language].solution.sourceFile}
                    </div>
                  }
                  key={languages[language].solution.sourceFile}
                >
                  <div
                    style={{
                      padding: "0px 10px",
                      background: "#181C22",
                      height: "88vh"
                    }}
                  >
                    <CodeEditor
                      language={languages[language].name}
                      theme={"monokai"}
                      lineNumbers={true}
                      code={solution_code}
                      onEditorChange={() => {}}
                      className="playback"
                    />
                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <div className="editor-file">
                      {languages[language].test.sourceFile}
                    </div>
                  }
                  key={languages[language].test.sourceFile}
                >
                  <div
                    style={{
                      padding: "0px 10px",
                      background: "#181C22",
                      height: "88vh"
                    }}
                  >
                    <CodeEditor
                      language={languages[language].name}
                      theme={"monokai"}
                      lineNumbers={true}
                      code={test_code}
                      onEditorChange={() => {}}
                      className="playback"
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
            <div style={{ width: "50%", padding: "8px 8px 8px 4px" }}>
              <Tabs
                defaultActiveKey="1"
                onChange={() => {}}
                tabBarStyle={{
                  background: "#181C22",
                  margin: 0,
                  padding: "0 10px 16px",
                  border: "none"
                }}
                className="interviewer-tabs"
              >
                <TabPane
                  tab={<div className="interviewer-tab">{"Output"}</div>}
                  key="1"
                >
                  <Terminal
                    terminal={generateCodeRoomTerminalLines(
                      output,
                      participants,
                      colors
                    )}
                    height="88vh"
                    language={language}
                    code={solution_code}
                    dependencies={test_code}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <div className="interviewer-tab">{"Interviewer Notes"}</div>
                  }
                  key="2"
                >
                  <div style={{ padding: "0px 10px", background: "#181C22" }}>
                    <MarkdownNotes
                      value={markdownNotes}
                      onMarkdownEditorChange={this.onMarkdownEditorChange}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              padding: "0 10px",
              justifyContent: "space-between",
              height: "3vh"
            }}
          >
            {play ? (
              <Icon
                type="pause"
                style={{ color: "white", fontSize: "28px" }}
                onClick={this.onClickPause}
                className="playback-button-icon"
              />
            ) : (
              <Icon
                type="caret-right"
                style={{ color: "white", fontSize: "28px" }}
                onClick={this.onClickPlay}
                className="playback-button-icon"
              />
            )}
            <div style={{ flex: 1, padding: "10px" }}>
              <Slider
                onChange={this.onSliderChange}
                value={sliderState}
                min={0}
                max={sliderLength}
                tooltipVisible={false}
                className="playback"
              />
            </div>
            <div
              style={{
                color: "white",
                margin: "0 20px",
                lineHeight: 1,
                textAlign: "center"
              }}
            >
              <div>{moment(createdAt).format("h:mm a")}</div>
              <div style={{ color: "rgba(191, 191, 191, 0.85)" }}>
                {moment(createdAt).format("MMM D")}
              </div>
            </div>
            <Link to="/coderoom/interviews">
              <Button className="ide-btn back-to-dashboard">Dashboard</Button>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const PlaybackContext = props => (
  <SocketContext.Consumer>
    {socket => <Playback socket={socket} {...props} />}
  </SocketContext.Consumer>
);

const mapStateToProps = ({ auth }) => ({
  auth
});

export default connect(
  mapStateToProps,
  {}
)(PlaybackContext);
