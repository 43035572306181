import React, { Component } from "react";
import { Row, Col, Card } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import isEmpty from "lodash.isempty";
import Loading from "../../UI/Loading";
import {
  fetchPlans,
  fetchSubscription,
  deleteSubscription,
  fetchAccount,
  fetchInvoice,
  updateSubscription
} from "../../../actions";

import "./styles.css";
import Plans from "./Plans";
import Payment from "./Payment";

class Billing extends Component {
  state = {
    selectedPlanName: null
  };

  componentDidMount() {
    this.props.fetchPlans();
    this.props.fetchSubscription();
    this.props.fetchAccount();
    this.props.fetchInvoice();
  }

  cancelSubscription = e => {
    e.preventDefault();
    this.props.deleteSubscription();
  };

  selectPlan = name => this.setState({ selectedPlanName: name });

  renderSubscribeFlow() {
    const { selectedPlanName } = this.state;
    console.log("renderSubscribeFlow");
    if (selectedPlanName) {
      return this.renderPayment("new");
    } else return <Plans selectPlan={this.selectPlan} />;
  }

  renderPayment(type) {
    const { selectedPlanName } = this.state;
    return <Payment planName={selectedPlanName} type={type} />;
  }

  render() {
    console.log(this.props);
    const { auth, plans, subscription, account, invoice } = this.props;
    let subscribedPlanName = Object.keys(subscription.data).length
      ? subscription.data.stripe.plan
      : null;

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    });

    if (
      auth.loading ||
      plans.loading ||
      subscription.loading ||
      account.loading ||
      invoice.loading
    ) {
      return <Loading height={"50vh"} />;
    } else if (isEmpty(subscription.data)) {
      return this.renderSubscribeFlow();
    } else
      return (
        <Col span={16} offset={4}>
          <Card title="Billing Details">
            <div className="profile-table-row">
              <div className="profile-table-key">Plan</div>
              <div className="profile-table-value">
                {!subscribedPlan(plans, subscribedPlanName) ? (
                  <p>You haven’t signed up for a plan yet.</p>
                ) : (
                  <React.Fragment>
                    <p>{`${
                      subscribedPlan(plans, subscribedPlanName).stripe.product
                    } plan, $${subscribedPlan(plans, subscribedPlanName).stripe
                      .amount / 100} per month`}</p>
                    <ul>
                      <li className="feature">{`${
                        subscribedPlan(plans, subscribedPlanName)
                          .max_included_interviews
                      } interviews`}</li>
                      <li className="feature">
                        {`$10 per interview in excess of ${
                          subscribedPlan(plans, subscribedPlanName)
                            .max_included_interviews
                        } interviews`}
                      </li>
                      <li className="feature">Unlimited Team Members</li>
                      <li className="feature">Unlimited Question Templates</li>
                      <li className="feature">Slack Integration</li>
                    </ul>
                  </React.Fragment>
                )}
              </div>
              {account.data.stripe &&
              account.data.stripe.sourceBrand &&
              account.data.stripe.sourceLast4 ? (
                <div>
                  <Link to="/settings/plans" className="update">
                    Select Plan
                  </Link>
                </div>
              ) : null}
            </div>
            <div className="profile-table-row">
              <div className="profile-table-key">Payment</div>
              <div className="profile-table-value payment-details">
                {account.data.stripe &&
                account.data.stripe.sourceBrand &&
                account.data.stripe.sourceLast4 ? (
                  <React.Fragment>
                    <div
                      className={`card-brand ${account.data.stripe.sourceBrand.toLowerCase()}`}
                    />
                    <p>
                      &bull;&bull;&bull;&bull;
                      {account.data.stripe.sourceLast4}
                    </p>
                  </React.Fragment>
                ) : (
                  <p>No card on file.</p>
                )}
              </div>
              <div>
                <Link to="/settings/payment" className="update">
                  Update Card
                </Link>
              </div>
            </div>
            <div className="profile-table-row">
              <div className="profile-table-key">Billing Cycle</div>
              <div className="profile-table-value">
                <p>
                  {isEmpty(invoice.data)
                    ? "You don’t have an active subscription."
                    : `Next payment of ${formatter.format(
                        invoice.data.total / 100
                      )} will be billed on ${nextBillingCycle(
                        invoice.data.period_end
                      )}`}
                  {/* {subscribedPlan(plans, subscribedPlanName)
                    ? `Next payment of ${formatter.format(
                        subscribedPlan(plans, subscribedPlanName).stripe
                          .flat_amount / 100
                      )} will be billed on ${nextBillingCycle(
                        subscription.data.stripe.currentPeriodEnd
                      )}`
                    : "You don’t have an active subscription."} */}
                </p>
              </div>
              {subscribedPlanName ? (
                <div>
                  <button className="update" onClick={this.cancelSubscription}>
                    Cancel
                  </button>
                </div>
              ) : null}
            </div>
          </Card>
        </Col>
      );
  }
}

const mapStateToProps = ({ auth, plans, subscription, account, invoice }) => ({
  auth,
  plans,
  subscription,
  account,
  invoice
});

export default connect(
  mapStateToProps,
  {
    fetchPlans,
    fetchSubscription,
    deleteSubscription,
    fetchAccount,
    fetchInvoice,
    updateSubscription
  }
)(Billing);

const subscribedPlan = (plans, nickname) => {
  if (nickname && plans.data.length) {
    return plans.data.find(plan => plan.stripe.nickname === nickname);
  }
  return null;
};

const nextBillingCycle = timestamp => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  if (timestamp) {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }
  return null;
};
