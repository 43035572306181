import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, withRouter } from "react-router-dom";
import * as io from "socket.io-client";
import axios from "axios";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";


import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reducers from "./reducers";
import SocketContext from "./socket-context";
import "antd/dist/antd.css";
import "ant-design-pro/dist/ant-design-pro.css"; // Import whole style
import "./index.css";

window.axios = axios;
const store = createStore(
  reducers,
  {},
  compose(
    applyMiddleware(reduxThunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const Top = withRouter(ScrollToTop);

const socket = io(process.env.REACT_APP_API_BASE_URL);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <SocketContext.Provider value={socket}>
        <Top>
          <App />
        </Top>
      </SocketContext.Provider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
