import React from "react";
import {
  FileExplorer,
  CodeMirror,
  BrowserPreview,
  SandpackProvider
} from "react-smooshpack/es/components";
import "react-smooshpack/dist/styles.css";

const files = {
  "/index.js": {
    code: "document.body.innerHTML = `<div>hello</div>`"
  }
};

class Terminal extends React.Component {
  render() {
    const { terminal, height, language, code, dependencies } = this.props;
    if (language !== "HTML/CSS/JS") {
      return <Console terminal={terminal} height={height} />;
    } else {
      return (
        <Browser height={height} code={code} dependencies={dependencies} />
      );
    }
  }
}

class Console extends React.Component {
  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  render() {
    const { terminal, height } = this.props;
    return (
      <div
        style={{
          fontFamily: `"Roboto Mono", Menlo, "Ubuntu Mono", Monaco, Consolas,
  "source-code-pro", monospace`,
          fontSize: "14px",
          lineHeight: "16px",
          color: "white",
          background: "#181C22",
          height,
          overflow: "auto",
          padding: "20px 10px 0px"
        }}
        className="terminal-output"
      >
        {terminal &&
          terminal.map((line, i) => (
            <div
              key={i}
              style={{
                width: "100%",
                padding: "8px",
                whiteSpace: "pre-wrap"
              }}
            >
              {line}
            </div>
          ))}
        <div
          style={{ float: "left", clear: "both" }}
          ref={el => {
            this.messagesEnd = el;
          }}
        />
      </div>
    );
  }
}
class Browser extends React.Component {
  componentDidMount() {
    const { height } = this.props;
    var iframe = document.querySelector("iframe");

    iframe.style.minHeight = `calc(${height} - 6vh)`;
  }
  render() {
    const { height, code, dependencies } = this.props;
    const files = {
      "/index.js": {
        code
      },
      "/package.json": {
        code: dependencies
      }
    };
    return (
      <div style={{ height }} className="terminal-output">
        <SandpackProvider files={files} entry="/index.js">
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              backgroundColor: "white"
            }}
          >
            <BrowserPreview style={{ flex: 1 }} />
          </div>
        </SandpackProvider>
      </div>
    );
  }
}

export default Terminal;
