import React from "react";
import { Button, Modal, Input } from "antd";
import { info } from "../../constants";
import Markdown from "../UI/MarkdownJSX";
import Highlight from "react-highlight";
import "highlight.js/styles/github-gist.css";

const OpenNewTab = ({ children, ...props }) => (
  <a {...props} target="_blank">
    {children}
  </a>
);

const HighlightCode = ({ children, ...props }) => {
  console.log({ children });
  return <Highlight {...props}>{children}</Highlight>;
};

const MarkdownCode = ({ children, ...props }) => (
  <code
    {...props}
    style={{
      background: "#f2f5f7",
      borderRadius: "3px",
      padding: "3px"
    }}
  >
    {children}
  </code>
);

class LanguageInfoModal extends React.Component {
  state = {
    visible: false
  };

  handleBtnClick = () => this.setState({ visible: !this.state.visible });

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  render() {
    const { visible } = this.state;
    const { language, className } = this.props;
    console.log({ language });
    const { title, body } = info[language];

    return (
      <React.Fragment>
        <Button
          className={className || "ide-btn"}
          onClick={this.handleBtnClick}
        >
          Info
        </Button>
        <Modal
          title={
            <div style={{ padding: "0 10px" }}>
              <h2>{title.header}</h2>
              <p style={{ margin: 0 }}>{title.subheader}</p>
            </div>
          }
          visible={visible}
          bodyStyle={{ maxHeight: "60vh", overflowY: "auto", lineHeight: 1.6 }}
          width={"60vw"}
          onCancel={this.handleCancel}
          footer={null}
          width={640}
        >
          <Markdown
            className="modal-info-content"
            children={body}
            options={{
              overrides: {
                a: {
                  component: OpenNewTab
                },
                code: {
                  component: HighlightCode
                },
                MarkdownCode: {
                  component: MarkdownCode
                }
              }
            }}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default LanguageInfoModal;
