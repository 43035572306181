import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Menu, Icon, Spin, Dropdown } from "antd";

import {
  RightEnd,
  CurrentUserDropdown,
  StyledAvatar,
  ToggleSiderMenuIcon
} from "./styles";

import { logoutUser } from "../../actions";

import { withRouter, Link } from "react-router-dom";

class GlobalHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.inputRef = React.createRef();
  }

  toggle = () => {
    const { collapsed, onCollapse } = this.props;
    onCollapse(!collapsed);
  };

  renderNotification() {
    const { interviews } = this.props;
    if (interviews.error) {
      if (interviews.error.message === "EXCEEDED_FREE_QUOTA") {
        return (
          <h3>
            {`🔔 You have exceed your free interviews quota in your trial. `}
            <Link to="/settings" style={{ color: "#1890ff" }}>
              Upgrade your plan
            </Link>
          </h3>
        );
      }
    }
  }

  render() {
    const { currentUser, collapsed, onMenuClick } = this.props;
    const menu = (
      <Menu selectedKeys={[]} onClick={onMenuClick}>
        <Menu.Item>
          <Link to="/settings">
            <Icon
              type="setting"
              style={{
                minWidth: "12px",
                marginRight: "8px"
              }}
            />
            Settings
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="logout"
          onClick={() =>
            this.props.logoutUser(() => this.props.history.push("/login"))
          }
        >
          <Icon type="logout" />
          Logout
        </Menu.Item>
      </Menu>
    );

    return (
      <React.Fragment>
        <ToggleSiderMenuIcon
          type={collapsed ? "menu-unfold" : "menu-fold"}
          onClick={this.toggle}
        />
        {this.renderNotification()}
        <RightEnd>
          {currentUser.name ? (
            <Dropdown overlay={menu}>
              <CurrentUserDropdown>
                <StyledAvatar size="small" src={currentUser.avatar} />
                <span>{currentUser.name}</span>
              </CurrentUserDropdown>
            </Dropdown>
          ) : (
            <Spin size="small" style={{ marginLeft: 8 }} />
          )}
        </RightEnd>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth, interviews }) => ({
  auth,
  interviews
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { logoutUser }
  )
)(GlobalHeader);
