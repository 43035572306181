import React from "react";
import { Button, Modal, Input, Icon } from "antd";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";
import { InlineMath, BlockMath } from "react-katex";
import escapeRegExp from "lodash.escaperegexp";
import CodeMirrorEditor from "../Editor/CodeMirrorEditor";
import Markdown from "../UI/MarkdownJSX";

import { fetchQuestions } from "../../actions";

const { Search } = Input;

class QuestionsModal extends React.Component {
  state = {
    visible: false,
    questionData: [],
    selected: null
  };

  componentDidUpdate(prevProps) {
    const { selectedQuestions, questions } = this.props;

    if (
      !isEqual(questions, prevProps.questions) ||
      !isEqual(selectedQuestions, prevProps.selectedQuestions)
    ) {
      let data = this.props.questions.data.map(q => {
        if (selectedQuestions.find(s => s._id === q._id)) {
          return { ...q, selectedQuestion: true };
        }
        return { ...q, selectedQuestion: false };
      });
      let selected = data.length > 0 ? 0 : null;

      this.setState({ questionData: data, selected });
    }
  }

  handleBtnClick = () => this.setState({ visible: !this.state.visible });

  handleQuestionClick = index => this.setState({ selected: index });

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  handleSelect = () => {
    const { questionData, visible, selected } = this.state;
    if (
      selected !== null &&
      questionData[selected] &&
      !questionData[selected].selectedQuestion
    ) {
      this.props.addSelectedQuestion(questionData[selected]);
    }
    this.setState({ visible: false });
  };

  render() {
    const { questionData, visible, selected } = this.state;
    return (
      <React.Fragment>
        <Button
          type="dashed"
          onClick={this.handleBtnClick}
          style={{ width: "60%" }}
        >
          <Icon type="plus" /> Add Question
        </Button>
        <Modal
          title="Questions"
          visible={visible}
          bodyStyle={{ height: "60vh" }}
          width={"60vw"}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="replace" type="primary" onClick={this.handleSelect}>
              Select
            </Button>
          ]}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <div
              style={{
                width: "50%",
                borderRight: "1px solid rgba(191, 191, 191, 0.85)",
                paddingRight: "10px"
              }}
            >
              <Search
                placeholder="Search questions..."
                onSearch={this.handleSearch}
                style={{ flex: 1, paddingBottom: "10px" }}
                enterButton
              />
              {questionData.map((record, i) => (
                <div key={record._id} style={{ paddingBottom: "10px" }}>
                  <div
                    style={{
                      background: `${
                        selected === i ? "rgba(199, 209, 217, 0.2)" : "white"
                      }`,
                      border: "1px solid rgba(191, 191, 191, 0.85)",
                      padding: "10px",
                      cursor: "pointer",
                      display: "flex"
                    }}
                    onClick={() => this.handleQuestionClick(i)}
                  >
                    <div>
                      <h4 style={{ margin: 0 }}>{record.title}</h4>
                      <div>{record.description}</div>
                      <div>{record.language}</div>
                    </div>
                    {record.selectedQuestion ? (
                      <Icon
                        type="check-circle"
                        style={{ marginLeft: "auto", color: "green" }}
                      />
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
            <div style={{ width: "100%", maxWidth: "36vw" }}>
              {selected !== null && questionData[selected] ? (
                <React.Fragment>
                  <div
                    style={{
                      borderBottom: "1px solid rgba(191, 191, 191, 0.85)",
                      padding: "0 10px 10px 10px"
                    }}
                  >
                    <h4 style={{ margin: 0 }}>
                      {questionData[selected].title}
                    </h4>
                    <div>{questionData[selected].description}</div>
                    <div>{questionData[selected].language}</div>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <Markdown
                      children={questionData[selected].question}
                      style={{ overflow: "auto", height: "48vh" }}
                      options={{
                        namedCodesToUnicode: {
                          le: "\u2264",
                          ge: "\u2265"
                        },
                        overrides: {
                          h3: {
                            props: {
                              style: { color: "white" }
                            }
                          },
                          pre: {
                            props: {
                              className: "question-markdown-pre"
                            }
                          },
                          InlineMath: {
                            component: InlineMath
                          }
                        }
                      }}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <div />
              )}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default QuestionsModal;
