import axios from "axios";
import {
  FETCH_CURRENT_USER_STARTED,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_CURRENT_USER_FAILURE,
  LOGIN_USER_STARTED,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  SIGNUP_USER_STARTED,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  LOGOUT_USER,
  CLEAR_AUTH_STATE,
  FETCH_COMPILERS_STARTED,
  FETCH_COMPILERS_SUCCESS,
  FETCH_COMPILERS_FAILURE,
  FETCH_INTERVIEWS_STARTED,
  FETCH_INTERVIEWS_SUCCESS,
  FETCH_INTERVIEWS_FAILURE,
  FETCH_ONE_INTERVIEW_STARTED,
  FETCH_ONE_INTERVIEW_SUCCESS,
  FETCH_ONE_INTERVIEW_FAILURE,
  CREATE_INTERVIEWS_STARTED,
  CREATE_INTERVIEWS_FAILURE,
  DELETE_INTERVIEWS_STARTED,
  DELETE_INTERVIEWS_FAILURE,
  UPDATE_INTERVIEWS_STARTED,
  UPDATE_INTERVIEWS_FAILURE,
  FETCH_ACCOUNT_STARTED,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_FAILURE,
  CREATE_ACCOUNT_STARTED,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
  FETCH_TEAM_MEMBERS_STARTED,
  FETCH_TEAM_MEMBERS_SUCCESS,
  FETCH_TEAM_MEMBERS_FAILURE,
  CREATE_TEAM_MEMBER_STARTED,
  CREATE_TEAM_MEMBER_SUCCESS,
  CREATE_TEAM_MEMBER_FAILURE,
  DELETE_TEAM_MEMBER_STARTED,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_FAILURE,
  UPDATE_TEAM_MEMBER_STARTED,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_FAILURE,
  FETCH_QUESTIONS_STARTED,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILURE,
  CREATE_QUESTIONS_STARTED,
  CREATE_QUESTIONS_FAILURE,
  DELETE_QUESTIONS_STARTED,
  DELETE_QUESTIONS_FAILURE,
  UPDATE_QUESTIONS_STARTED,
  UPDATE_QUESTIONS_FAILURE,
  IMPORT_QUESTIONS_STARTED,
  IMPORT_QUESTIONS_FAILURE,
  DELETE_SLACK_INTEGRATION_STARTED,
  DELETE_SLACK_INTEGRATION_FAILURE,
  RUN_TESTS_STARTED,
  RUN_TESTS_SUCCESS,
  RUN_TESTS_FAILURE
} from "./types";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export * from "./billing.js";
export * from "./invite.js";
export * from "./tour.js";

export const fetchUser = () => async dispatch => {
  try {
    dispatch({ type: FETCH_CURRENT_USER_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/current_user`, {
      withCredentials: true
    });
    console.log("fetchUser: ", res.data);
    dispatch({ type: FETCH_CURRENT_USER_SUCCESS, payload: res.data });
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCH_CURRENT_USER_FAILURE,
      payload: error.response.data
    });
  }
};

export const loginUser = (email, password) => async dispatch => {
  try {
    dispatch({ type: LOGIN_USER_STARTED });
    const res = await axios.post(
      `${API_BASE_URL}/auth/v1/login`,
      {
        email,
        password
      },
      { withCredentials: true }
    );
    dispatch({ type: LOGIN_USER_SUCCESS, payload: res.data });
    if (!res.data.message) {
      dispatch(fetchUser());
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: LOGIN_USER_FAILURE, payload: error.response.data });
  }
};

export const signUpUser = (
  email,
  password,
  name,
  companyName
) => async dispatch => {
  try {
    dispatch({ type: SIGNUP_USER_STARTED });
    const res = await axios.post(
      `${API_BASE_URL}/auth/v1/signup`,
      {
        email,
        password,
        name,
        companyName
      },
      { withCredentials: true }
    );
    dispatch({
      type: SIGNUP_USER_SUCCESS,
      payload: res.data
    });
    dispatch(fetchUser());
  } catch (error) {
    dispatch({ type: SIGNUP_USER_FAILURE, payload: error.response.data });
  }
};

export const logoutUser = callback => async dispatch => {
  const res = await axios.get(`${API_BASE_URL}/auth/v1/logout`, {
    withCredentials: true
  });
  dispatch({ type: LOGOUT_USER, payload: res.data });
  callback();
};

export const clearAuthState = () => dispatch => {
  dispatch({ type: CLEAR_AUTH_STATE, payload: {} });
};

export const fetchCompilers = () => async dispatch => {
  try {
    dispatch({ type: FETCH_COMPILERS_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/compilers`, {
      withCredentials: true
    });
    let { compilers } = res.data;
    console.log({ compilers });
    dispatch({ type: FETCH_COMPILERS_SUCCESS, payload: compilers });
  } catch (error) {
    dispatch({ type: FETCH_COMPILERS_FAILURE, payload: error.response.data });
  }
};

export const fetchInterviews = (type = "CodeRoom") => async dispatch => {
  try {
    dispatch({ type: FETCH_INTERVIEWS_STARTED });
    const res = await axios.get(
      `${API_BASE_URL}/api/v1/interviews?type=${type}`,
      {
        withCredentials: true
      }
    );
    let { interviews } = res.data;
    dispatch({ type: FETCH_INTERVIEWS_SUCCESS, payload: interviews });
  } catch (error) {
    dispatch({ type: FETCH_INTERVIEWS_FAILURE, payload: error.response.data });
  }
};

export const fetchOneInterview = roomId => async dispatch => {
  try {
    dispatch({ type: FETCH_ONE_INTERVIEW_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/interviews/${roomId}`, {
      withCredentials: true
    });
    let { interview } = res.data;
    dispatch({ type: FETCH_ONE_INTERVIEW_SUCCESS, payload: interview });
  } catch (error) {
    dispatch({
      type: FETCH_ONE_INTERVIEW_FAILURE,
      payload: error.response.data
    });
  }
};

export const createInterview = (
  type,
  data = {},
  callback = () => {}
) => async dispatch => {
  try {
    dispatch({ type: CREATE_INTERVIEWS_STARTED });
    await axios.post(
      `${API_BASE_URL}/api/v1/interviews`,
      {
        type,
        data
      },
      { withCredentials: true }
    );
    dispatch(fetchInterviews(type));
    callback();
  } catch (error) {
    dispatch({ type: CREATE_INTERVIEWS_FAILURE, payload: error.response.data });
  }
};

export const deleteInterview = (id, type) => async dispatch => {
  try {
    dispatch({ type: DELETE_INTERVIEWS_STARTED });

    await axios.delete(`${API_BASE_URL}/api/v1/interviews`, {
      data: {
        id
      },
      withCredentials: true
    });
    dispatch(fetchInterviews(type));
  } catch (error) {
    dispatch({ type: DELETE_INTERVIEWS_FAILURE, payload: error });
  }
};

export const updateInterview = (
  id,
  assignedInterviewers,
  scheduled_date
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_INTERVIEWS_STARTED });
    await axios.put(
      `${API_BASE_URL}/api/v1/interviews`,
      {
        id,
        assignedInterviewers,
        scheduled_date
      },
      { withCredentials: true }
    );
    dispatch(fetchInterviews());
  } catch (error) {
    dispatch({ type: UPDATE_INTERVIEWS_FAILURE, payload: error.response.data });
  }
};

export const fetchAccount = () => async dispatch => {
  try {
    dispatch({ type: FETCH_ACCOUNT_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/account`, {
      withCredentials: true
    });
    let { account } = res.data;
    dispatch({ type: FETCH_ACCOUNT_SUCCESS, payload: account });
  } catch (error) {
    dispatch({ type: FETCH_ACCOUNT_FAILURE, payload: error.response.data });
  }
};

export const fetchTeamMembers = () => async dispatch => {
  try {
    dispatch({ type: FETCH_TEAM_MEMBERS_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/account/team`, {
      withCredentials: true
    });
    let { members } = res.data;
    dispatch({ type: FETCH_TEAM_MEMBERS_SUCCESS, payload: members });
  } catch (error) {
    dispatch({
      type: FETCH_TEAM_MEMBERS_FAILURE,
      payload: error.response.data
    });
  }
};

export const createTeamMember = (
  _invite,
  email,
  password,
  name,
  _account,
  callback
) => async dispatch => {
  try {
    dispatch({ type: CREATE_TEAM_MEMBER_STARTED });
    const res = await axios.post(
      `${API_BASE_URL}/api/v1/account/team/user`,
      {
        _invite,
        email,
        password,
        name,
        _account
      },
      { withCredentials: true }
    );
    dispatch({ type: CREATE_TEAM_MEMBER_SUCCESS, payload: res.data });
    callback();
  } catch (error) {
    dispatch({
      type: CREATE_TEAM_MEMBER_FAILURE,
      payload: error.response.data
    });
  }
};

export const deleteTeamMember = (id, type) => async dispatch => {
  try {
    dispatch({ type: DELETE_TEAM_MEMBER_STARTED });

    await axios.delete(`${API_BASE_URL}/api/v1/account/team/user`, {
      data: {
        id,
        type
      },
      withCredentials: true
    });
    dispatch(fetchTeamMembers());
  } catch (error) {
    dispatch({ type: DELETE_TEAM_MEMBER_FAILURE, payload: error });
  }
};

export const updateTeamMember = (id, type, access) => async dispatch => {
  try {
    dispatch({ type: UPDATE_TEAM_MEMBER_STARTED });
    await axios.patch(
      `${API_BASE_URL}/api/v1/account/team/user`,
      {
        id,
        type,
        access
      },
      { withCredentials: true }
    );
    dispatch(fetchTeamMembers());
  } catch (error) {
    dispatch({
      type: UPDATE_TEAM_MEMBER_FAILURE,
      payload: error.response.data
    });
  }
};

export const createAccount = (
  _user,
  companyName,
  callback
) => async dispatch => {
  try {
    dispatch({ type: CREATE_ACCOUNT_STARTED });
    const res = await axios.post(
      `${API_BASE_URL}/api/v1/account/user`,
      {
        _user,
        companyName
      },
      { withCredentials: true }
    );
    let { account } = res.data;
    dispatch(clearAuthState());
    callback();
    dispatch({ type: CREATE_ACCOUNT_SUCCESS, payload: account });
  } catch (error) {
    dispatch({ type: CREATE_ACCOUNT_FAILURE, payload: error.response.data });
  }
};

export const fetchQuestions = (type = "CodeRoom") => async dispatch => {
  try {
    dispatch({ type: FETCH_QUESTIONS_STARTED });
    const res = await axios.get(
      `${API_BASE_URL}/api/v1/questions?type=${type}`,
      {
        withCredentials: true
      }
    );
    let { questions } = res.data;
    dispatch({ type: FETCH_QUESTIONS_SUCCESS, payload: questions });
  } catch (error) {
    dispatch({ type: FETCH_QUESTIONS_FAILURE, payload: error.response.data });
  }
};

export const createQuestion = (
  language,
  question,
  title,
  description
) => async dispatch => {
  try {
    dispatch({ type: CREATE_QUESTIONS_STARTED });
    await axios.post(
      `${API_BASE_URL}/api/v1/questions`,
      {
        language,
        question,
        title,
        description
      },
      { withCredentials: true }
    );
    dispatch(fetchQuestions());
  } catch (error) {
    dispatch({ type: CREATE_QUESTIONS_FAILURE, payload: error.response.data });
  }
};

export const deleteQuestion = id => async dispatch => {
  try {
    dispatch({ type: DELETE_QUESTIONS_STARTED });

    await axios.delete(`${API_BASE_URL}/api/v1/questions`, {
      data: {
        id
      },
      withCredentials: true
    });
    dispatch(fetchQuestions());
  } catch (error) {
    dispatch({ type: DELETE_QUESTIONS_FAILURE, payload: error });
  }
};

export const updateQuestion = (
  id,
  language,
  question,
  title,
  description
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_QUESTIONS_STARTED });
    await axios.put(
      `${API_BASE_URL}/api/v1/questions`,
      {
        id,
        language,
        question,
        title,
        description
      },
      { withCredentials: true }
    );
    dispatch(fetchQuestions());
  } catch (error) {
    dispatch({ type: UPDATE_QUESTIONS_FAILURE, payload: error.response.data });
  }
};

export const importQuestions = (
  data,
  callback,
  errCallback
) => async dispatch => {
  try {
    dispatch({ type: IMPORT_QUESTIONS_STARTED });
    await axios.post(
      `${API_BASE_URL}/api/v1/questions/import`,
      {
        data
      },
      { withCredentials: true }
    );
    dispatch(fetchQuestions());
    callback();
  } catch (error) {
    dispatch({ type: IMPORT_QUESTIONS_FAILURE, payload: error.response.data });
    errCallback();
  }
};

export const deleteSlackIntegration = () => async dispatch => {
  try {
    dispatch({ type: DELETE_SLACK_INTEGRATION_STARTED });

    await axios.delete(`${API_BASE_URL}/api/v1/integrations/slack`, {
      data: {},
      withCredentials: true
    });
    dispatch(fetchAccount());
  } catch (error) {
    dispatch({ type: DELETE_SLACK_INTEGRATION_FAILURE, payload: error });
  }
};

export const runTests = (id, code) => async dispatch => {
  try {
    dispatch({ type: RUN_TESTS_STARTED });
    let res = await axios.post(
      `${API_BASE_URL}/api/v1/questions/tests/evaluate`,
      {
        id,
        code
      },
      { withCredentials: true }
    );
    let { tests } = res.data;
    dispatch({ type: RUN_TESTS_SUCCESS, payload: tests });
  } catch (error) {
    dispatch({ type: RUN_TESTS_FAILURE, payload: error.response.data });
  }
};
