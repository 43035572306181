import axios from "axios";
import {
  FETCH_SUBSCRIPTION_STARTED,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILURE,
  CREATE_SUBSCRIPTION_STARTED,
  CREATE_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_STARTED,
  UPDATE_SUBSCRIPTION_FAILURE,
  DELETE_SUBSCRIPTION_STARTED,
  DELETE_SUBSCRIPTION_FAILURE,
  UPDATE_PAYMENT_SOURCE_STARTED,
  UPDATE_PAYMENT_SOURCE_SUCCESS,
  UPDATE_PAYMENT_SOURCE_FAILURE,
  DELETE_PAYMENT_SOURCE_STARTED,
  DELETE_PAYMENT_SOURCE_SUCCESS,
  DELETE_PAYMENT_SOURCE_FAILURE,
  FETCH_PLANS_STARTED,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_FAILURE,
  FETCH_INVOICE_STARTED,
  FETCH_INVOICE_SUCCESS,
  FETCH_INVOICE_FAILURE
} from "./types";

import { fetchUser, fetchAccount } from "./index";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchPlans = () => async dispatch => {
  try {
    dispatch({ type: FETCH_PLANS_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/billing/plans`, {
      withCredentials: true
    });
    let { plans } = res.data;
    dispatch({ type: FETCH_PLANS_SUCCESS, payload: plans });
  } catch (error) {
    dispatch({ type: FETCH_PLANS_FAILURE, payload: error.response.data });
  }
};

export const fetchSubscription = () => async dispatch => {
  try {
    dispatch({ type: FETCH_SUBSCRIPTION_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/billing/subscription`, {
      withCredentials: true
    });
    let { subscription } = res.data;
    dispatch({ type: FETCH_SUBSCRIPTION_SUCCESS, payload: subscription });
  } catch (error) {
    dispatch({
      type: FETCH_SUBSCRIPTION_FAILURE,
      payload: error.response.data
    });
  }
};

export const createSubscription = (
  token,
  plan,
  tax,
  address
) => async dispatch => {
  try {
    dispatch({ type: CREATE_SUBSCRIPTION_STARTED });
    await axios.put(
      `${API_BASE_URL}/api/v1/billing/source`,
      { source: token },
      { withCredentials: true }
    );

    await axios.post(
      `${API_BASE_URL}/api/v1/billing/subscription`,
      {
        plan,
        tax,
        address
      },
      { withCredentials: true }
    );
    dispatch(fetchSubscription());
    dispatch(fetchAccount());
    dispatch(fetchInvoice())
  } catch (error) {
    dispatch({
      type: CREATE_SUBSCRIPTION_FAILURE,
      payload: error.response.data
    });
  }
};

export const updateSubscription = (plan, callback) => async dispatch => {
  try {
    dispatch({ type: UPDATE_SUBSCRIPTION_STARTED });
    await axios.patch(
      `${API_BASE_URL}/api/v1/billing/subscription`,
      {
        plan
      },
      { withCredentials: true }
    );
    callback();
    dispatch(fetchSubscription());
    dispatch(fetchAccount());
    dispatch(fetchInvoice())
  } catch (error) {
    dispatch({
      type: UPDATE_SUBSCRIPTION_FAILURE,
      payload: error.response.data
    });
  }
};

export const deleteSubscription = () => async dispatch => {
  try {
    dispatch({ type: DELETE_SUBSCRIPTION_STARTED });

    await axios.delete(`${API_BASE_URL}/api/v1/billing/subscription`, {
      withCredentials: true
    });
    dispatch(fetchSubscription());
  } catch (error) {
    dispatch({
      type: DELETE_SUBSCRIPTION_FAILURE,
      payload: error.response.data
    });
  }
};

export const updatePaymentSource = (token, callback) => async dispatch => {
  try {
    dispatch({ type: UPDATE_PAYMENT_SOURCE_STARTED });
    await axios.put(
      `${API_BASE_URL}/api/v1/billing/source`,
      { source: token },
      { withCredentials: true }
    );
    callback();
    dispatch(fetchUser());
    dispatch(fetchAccount());
  } catch (error) {
    console.log({ error });
    dispatch({
      type: UPDATE_PAYMENT_SOURCE_FAILURE,
      payload: error.response.data
    });
  }
};

export const deletePaymentSource = () => async dispatch => {
  try {
    dispatch({ type: DELETE_PAYMENT_SOURCE_STARTED });

    await axios.delete(`${API_BASE_URL}/api/v1/billing/source`, {
      withCredentials: true
    });
    dispatch(fetchUser());
  } catch (error) {
    dispatch({
      type: DELETE_PAYMENT_SOURCE_FAILURE,
      payload: error.response.data
    });
  }
};

export const fetchInvoice = () => async dispatch => {
  try {
    dispatch({ type: FETCH_INVOICE_STARTED });
    const res = await axios.get(
      `${API_BASE_URL}/api/v1/billing/invoice/upcoming`,
      {
        withCredentials: true
      }
    );
    let { invoice } = res.data;
    dispatch({ type: FETCH_INVOICE_SUCCESS, payload: invoice });
  } catch (error) {
    dispatch({
      type: FETCH_INVOICE_FAILURE,
      payload: error.response.data
    });
  }
};
