import React, { Component } from "react";
import { Menu, Icon } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import isEmpty from "lodash.isempty";

import { updateTourStatus, startTour } from "../../actions";

import logo from "./assets/icon.svg";

const constantKeys = {
  "/integrations": "1",
  "/settings": "2"
};

const topMenuKeys = {
  "1": {
    "/coderoom/interviews": { key: "3", title: "Interviews", icon: "book" },
    "/coderoom/questions": {
      key: "4",
      title: "Questions",
      icon: "question-circle"
    }
  },
  "2": {
    "/codechallenge/interviews": {
      key: "5",
      title: "Interviews",
      icon: "book"
    },
    "/codechallenge/questions": {
      key: "6",
      title: "Questions",
      icon: "question-circle"
    }
  }
};

const SiderMenu = props => {
  const {
    collapsed,
    menuKey,
    topMenuKey,
    changeMenuKey,
    auth,
    updateTourStatus
  } = props;
  const styles = {
    logo: {
      color: "white",
      fontSize: "30px",
      verticalAlign: "middle"
    },
    h1: {
      color: "white",
      display: "inline-block",
      verticalAlign: "middle",
      fontSize: "20px",
      margin: "0 0 0 14px",
      fontFamily:
        "'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif",
      fontWeight: "600"
    }
  };

  return (
    <Menu
      mode="inline"
      theme="dark"
      inlineCollapsed={collapsed}
      selectedKeys={[menuKey]}
    >
      <Menu.Item
        title="CoderTest"
        key="index"
        style={{
          marginBottom: "24px",
          marginTop: "24px",
          height: "100%",
          backgroundColor: "#001529"
        }}
      >
        <img
          src={logo}
          alt="CoderTest Logo"
          style={{
            height: "50px",
            width: "50px",
            minWidth: "50px",
            marginLeft: "-14px"
          }}
        />
        {collapsed ? null : <span style={styles.h1}>CoderTest</span>}
      </Menu.Item>
      {Object.entries(topMenuKeys[topMenuKey]).map(([link, value]) => (
        <Menu.Item key={value.key}>
          <Link to={link} onClick={() => changeMenuKey("side", value.key)}>
            <Icon type={value.icon} />
            <span>{value.title}</span>
          </Link>
        </Menu.Item>
      ))}
      <Menu.Item key="1" title="Integrations">
        <Link to="/integrations" onClick={() => changeMenuKey("side", "1")}>
          <Icon type="branches" />
          <span>Integrations</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="2" title="Settings">
        <Link to="/settings" onClick={() => changeMenuKey("side", "2")}>
          <Icon type="setting" />
          <span>Settings</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        key="7"
        title="Tutorial"
        style={{ position: "fixed", bottom: "60px", maxWidth: "200px" }}
        onClick={() => updateTourStatus(false, false, startTour)}
      >
        <Icon type="play-circle" />
        <span>Tutorial</span>
      </Menu.Item>
    </Menu>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { updateTourStatus, startTour }
  )
)(SiderMenu);
