import React from "react";
import { Upload, message, Button, Icon } from "antd";
import PapaParse from "papaparse/papaparse.min.js";
import { connect } from "react-redux";

import { importQuestions } from "../../actions";

class ImportQuestions extends React.Component {
  state = { data: [] };

  handleUploadFile = file => {
    const reader = new FileReader();
    const filename = file.name;

    reader.onload = e => {
      const csvData = PapaParse.parse(
        e.target.result,
        Object.assign(
          {},
          {
            error: () => message.error(`${filename} file upload failed.`)
          }
        )
      );

      console.log(csvData.data, filename);

      let headers = ["Title", "Description", "Question", "Language"];
      let [dataHeaders, ...body] = csvData.data;
      let missingHeaders = headers.filter(
        header => !dataHeaders.includes(header)
      );
      if (missingHeaders.length > 0) {
        return message.error(
          `${filename} file missing headers: ${missingHeaders.join(", ")}.`
        );
      }

      let indexes = {};
      headers.map((header, i) => {
        if (indexes[header] || !dataHeaders.includes(header)) {
          return null;
        } else {
          indexes[header] = i;
          return;
        }
      });

      let result = body.map(row => ({
        language: row[indexes["Language"]],
        question: row[indexes["Question"]],
        title: row[indexes["Title"]],
        description: row[indexes["Description"]]
      }));

      console.log({ indexes, result });

      this.props.importQuestions(
        result,
        () => message.success(`${filename} file uploaded successfully`),
        () => message.error(`${filename} file upload failed.`)
      );
    };
    reader.readAsText(file);

    // Prevent upload
    return false;
  };

  render() {
    return (
      <Upload
        accept=".csv, text/csv"
        beforeUpload={this.handleUploadFile}
        showUploadList={false}
      >
        <Button>
          <Icon type="upload" /> Import
        </Button>
      </Upload>
    );
  }
}

const mapStateToProps = ({ questions }) => ({
  questions
});

export default connect(
  mapStateToProps,
  { importQuestions }
)(ImportQuestions);
