import React from "react";
import { Card, Button, Icon, Progress } from "antd";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isempty";
import { steps } from "./steps";

import { nextTourStep, updateTourStatus } from "../../actions";
import "./styles.css";

const Tooltip = props => {
  const {
    continuous,
    index,
    isLastStep,
    step,
    backProps,
    primaryProps,
    closeProps,
    skipProps,
    tooltipProps,
    size,
    nextTourStep
  } = props;
  console.log(props);
  return (
    <div {...tooltipProps}>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-between",
          padding: "10px"
        }}
      >
        <div>
          {index > 0 && (
            <span
              style={{ cursor: "pointer", marginRight: "10px" }}
              {...backProps}
            >
              <Icon type="rollback" />
            </span>
          )}
          {!isLastStep && index === 0 && (
            <span style={{ cursor: "pointer" }} {...skipProps}>
              Skip
            </span>
          )}
        </div>
        <span style={{ cursor: "pointer" }} {...closeProps}>
          <Icon type="close" />
        </span>
      </div>
      <Card
        style={{ border: "none" }}
        bodyStyle={{ paddingTop: "10px", minWidth: "200px", maxWidth: "350px" }}
        title={step.title || null}
      >
        {step.content}
      </Card>
      <div className="tooltip-footer">
        <div style={{ margin: "0px auto 0px 15px" }}>
          <Progress
            type="circle"
            percent={((index + 1) / size) * 100}
            showInfo={false}
            width={30}
            className="onboarding-step-progress"
          />
          <span>2 min left</span>
        </div>
        {/* {!isLastStep && <Button {...skipProps}>Skip</Button>} */}
        {/* {index > 0 && <Button {...backProps}>Go Back</Button>} */}
        <Button
          type="primary"
          {...primaryProps}
          style={{ marginRight: "10px" }}
        >
          {!isLastStep ? `Next` : `Finish`}
        </Button>
      </div>
    </div>
  );
};

const WrappedTooltip = connect(
  null,
  { nextTourStep }
)(Tooltip);

class Tour extends React.Component {
  state = {
    run: false,
    stepIndex: 0
  };

  componentDidMount() {
    const { auth, match, tour } = this.props;
    if (!isEmpty(auth.data) && !auth.loading) {
      if (auth.data.tour.skipped || auth.data.tour.completed) {
        return this.setState({ run: false });
      } else {
        let stepIndex = 0;
        if (match.path === "/coderoom/interviews") {
          stepIndex = 0;
        } else if (match.path === "/room/:_id") {
          stepIndex = 2;
        } else if (match.path === "/room/:_id/playback") {
          stepIndex = 11;
        }
        return this.setState({ run: true, stepIndex });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { tour, auth } = this.props;
    if (JSON.stringify(tour.data) !== JSON.stringify(prevProps.tour.data)) {
      if (
        tour.data.type === "NEXT" &&
        !auth.data.tour.skipped &&
        !auth.data.tour.completed
      ) {
        return this.setState(
          {
            run: false,
            stepIndex: this.state.stepIndex + 1
          },
          () => {
            setTimeout(() => {
              this.setState({ run: true });
            }, 400);
          }
        );
      } else if (tour.data.type === "STOP") {
        return this.setState({ run: false });
      } else if (tour.data.type === "START") {
        return this.setState({ run: true, stepIndex: 0 });
      }
    }
  }

  handleJoyrideCallback = data => {
    const { action, index, type, status } = data;
    console.log("JoyrideCallback", { data });
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ run: false, stepIndex: 0 });
      if (status === STATUS.SKIPPED) {
        return this.props.updateTourStatus(false, true);
      }
      if (status === STATUS.FINISHED) {
        return this.props.updateTourStatus(true, false);
      }
    } else if (action === ACTIONS.CLOSE) {
      this.setState({ stepIndex: index });
    } else if ([EVENTS.STEP_AFTER].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      // Update state to advance the tour
      this.setState({ run: true, stepIndex });
    } else if (action === ACTIONS.PREV && type === EVENTS.TARGET_NOT_FOUND) {
      const stepIndex = index;
      if (this.props.match.path !== steps[stepIndex].link) {
        this.props.history.goBack();
        return this.setState({ run: true, stepIndex: index });
      } else {
        return this.setState({ run: true, stepIndex });
      }
    } else if (type === EVENTS.TARGET_NOT_FOUND) {
      const stepIndex = index - 1;

      // Update state to advance the tour
      this.setState({ run: true, stepIndex });
    }

    // tslint:disable:no-console
    console.groupCollapsed(
      type === EVENTS.TOUR_STATUS ? `${type}:${status}` : type
    );
    console.log(data);
    console.groupEnd();
    // tslint:enable:no-console
  };

  render() {
    const { auth, nextTourStep } = this.props;
    const { run, stepIndex } = this.state;
    return (
      <Joyride
        steps={steps}
        stepIndex={stepIndex}
        spotlightClicks
        showProgress
        continuous
        run={run}
        disableOverlayClose
        callback={this.handleJoyrideCallback}
        tooltipComponent={WrappedTooltip}
      />
    );
  }
}

const mapStateToProps = ({ auth, tour }) => ({
  auth,
  tour
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { nextTourStep, updateTourStatus }
  )
)(Tour);
