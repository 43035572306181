import React from "react";
import { Button, Modal, Form, Select, DatePicker } from "antd";
import { connect } from "react-redux";
import moment from "moment";

import { updateInterview } from "../../actions";

const FormItem = Form.Item;
const { Option } = Select;

const CollectionCreateForm = Form.create()(
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        teamMembers,
        record
      } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal
          visible={visible}
          title="Edit Scheduled Interview"
          okText="Save"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <FormItem label="Assign an Interviewer">
              {getFieldDecorator("assignedInterviewers", {
                initialValue: record.assignedInterviewers,
                rules: [
                  {
                    required: true,
                    message: "Please assign an interviewer!"
                  }
                ]
              })(
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Assign an interviewer."
                >
                  {teamMembers.data.map(member => (
                    <Option key={member._id}>{member.name}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <Form.Item label="Select the date and time">
              {getFieldDecorator("scheduled_date", {
                initialValue: moment(record.scheduled_date),
                rules: [
                  {
                    type: "object",
                    required: true,
                    message: "Please select time!"
                  }
                ]
              })(<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class EditInterview extends React.Component {
  state = {
    visible: false
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.updateInterview(
        this.props.record._id,
        values.assignedInterviewers,
        values.scheduled_date.toISOString()
      );
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    return (
      <React.Fragment>
        <Button onClick={this.showModal}>Edit</Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          teamMembers={this.props.teamMembers}
          record={this.props.record}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ teamMembers }) => ({
  teamMembers
});

export default connect(
  mapStateToProps,
  { updateInterview }
)(EditInterview);
