export default {
  Bash: {
    title: {
      header: "Bash",
      subheader: "Running GNU bash 4.4"
    },
    body: `No extra information available. It should just work!

Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  },
  C: {
    title: {
      header: "C",
      subheader: "Running GCC 8.2"
    },
    body: `The gcc compiler is invoked with the following args: <MarkdownCode>gcc -pthread -lm</MarkdownCode>

You should declare a <MarkdownCode>int main()</MarkdownCode> in the <MarkdownCode>solution.c</MarkdownCode> file.

Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  },
  "C#": {
    title: {
      header: "C#",
      subheader: "Running .NET Core 2.2"
    },
    body: `You should declare a <MarkdownCode>static void Main</MarkdownCode> on a class named <MarkdownCode>Solution</MarkdownCode> in the <MarkdownCode>Solution.cs</MarkdownCode> file.

We also installed [NUnit](http://nunit.org/), which is a testing library.

When you run the <MarkdownCode>SolutionTest.cs</MarkdownCode> file, it is invoked with <MarkdownCode>dotnet test</MarkdownCode>. Here's a quick example of a NUnit test:    

  \`\`\`csharp
  using NUnit.Framework;

  public class SolutionTest
  {

      [Test]
      public void Test1()
      {
          Solution solution = new Solution();

          Assert.AreEqual(solution.Hello(), "Hello World!");
      }
  }
  \`\`\`

Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  },
  "C++": {
    title: {
      header: "C++",
      subheader: "Running GCC 8.2"
    },
    body: `The gcc compiler is invoked with the following args: <MarkdownCode>g++ -pthread</MarkdownCode>

You should declare a <MarkdownCode>int main()</MarkdownCode> in the <MarkdownCode>solution.cpp</MarkdownCode> file.

Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  },
  // Clojure: {
  //   name: "clojure",
  //   boilerplate:
  //     '(defn hello [times]\n  (prn "Hello, World")\n  (cond (> times 1) (hello (- times 1)))\n)\n\n(hello 5)',
  //   comments: {
  //     type: "single",
  //     prefix: ";",
  //     suffix: null
  //   }
  // },
  Elixir: {
    title: {
      header: "Elixir",
      subheader: "Running Elixir 1.6"
    },
    body: `You will be able to use Elixir's standard library and it's built-in test framework, ExUnit.
    
 Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  },
  // Erlang: {
  //   name: "erlang",
  //   boilerplate:
  //     '-module(solution).\n-export([start/0]).\n\n% To execute your Erlang program, please declare the module as "solution"\n% and define a method named "start" on it.\n\nstart() ->\n  hello(5).\n\nhello(0) ->\n  0;\nhello(N) ->\n  io:format("Hello, World~n"),\n  hello(N - 1).',
  //   comments: {
  //     type: "single",
  //     prefix: "%",
  //     suffix: null
  //   }
  // },
  "F#": {
    title: {
      header: "F#",
      subheader: "Running .NET Core 2.2"
    },
    body: `We installed [NUnit](http://nunit.org/), which is a testing library.

When you run the <MarkdownCode>SolutionTest.fs</MarkdownCode> file, it is invoked with <MarkdownCode>dotnet test</MarkdownCode>. Here's a quick example of a NUnit test:    

  \`\`\`fsharp
  namespace Tests

  open NUnit.Framework
  open Solution.Hello
  
  type TestClass () =
  
      [<SetUp>]
      member this.Setup () =
          ()
  
      [<Test>]
      member this.shouldSayHello () =
          Assert.AreEqual(SayHello, "Hello, World!")
  \`\`\`

Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  },
  Golang: {
    title: {
      header: "Golang",
      subheader: "Running Go 1.10"
    },
    body: `You should declare a <MarkdownCode>func main()</MarkdownCode> in the <MarkdownCode>main.go</MarkdownCode> file.

You will be able to use Golang's standard library and it's built-in test framework.

When you run the <MarkdownCode>main_test.go</MarkdownCode> file, it is invoked with <MarkdownCode>go test</MarkdownCode>.

Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  },
  // Haskell: {
  //   name: "haskell",
  //   boilerplate: 'main = putStrLn "Hello, World!"',
  //   comments: {
  //     type: "single",
  //     prefix: "--",
  //     suffix: null
  //   }
  // },
  "HTML/CSS/JS": {
    title: {
      header: "HTML/CSS/JS",
      subheader: "Running HTML 5"
    },
    body:``
  },
  Java: {
    title: {
      header: "Java",
      subheader: "Running OpenJDK 11."
    },
    body: `You should define a public class named <MarkdownCode>Solution</MarkdownCode> with a <MarkdownCode>public static void main</MarkdownCode> in the <MarkdownCode>Solution.java</MarkdownCode> file. 
    
You should define a public class named <MarkdownCode>SolutionTest</MarkdownCode> in the <MarkdownCode>SolutionTest.java</MarkdownCode> file.
    
We also installed [JUnit](http://junit.org/), which is a testing library.
    
When you run the <MarkdownCode>SolutionTest.java</MarkdownCode> file, it is invoked with JUnit on the classpath. Here's a quick example of a JUnit tests:    

  \`\`\`java
  import org.junit.*;
  import org.junit.runner.*;
  
  public class SolutionTest {
    @Test
    public void testHello() {
      // Assert
      Assert.assertEquals(Solution.hello(), "Hello, World!");
    }
  }
  \`\`\`

Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  },
  JavaScript: {
    title: {
      header: "JavaScript",
      subheader: "Running NodeJS v10."
    },
    body: `Check out [the Node compatibility table](https://node.green/), for a description of supported JavaScript features. 
        
We also included several npm packages available for your use:

* [underscore](http://underscorejs.org/), [lodash](https://lodash.com/) and [ramda](https://ramdajs.com/) for many useful helper functions.

* [mocha](https://www.npmjs.com/package/mocha), [sinon](https://www.npmjs.com/package/sinon), [chai](https://www.npmjs.com/package/chai) and [sinon-chai](https://www.npmjs.com/package/sinon-chai) testing libraries.

  When you run the <MarkdownCode>test.js</MarkdownCode> file, it is invoked with mocha.
    
  Here’s a quick example of how to use sinon and chai with mocha:

    \`\`\`javascript
    const chai = require("chai");
    const sinon = require("sinon");
    const sinonChai = require("sinon-chai");

    chai.should();
    chai.use(sinonChai);

    function hello(name, cb) {
      cb("hello " + name);
    }

    describe("hello()", function() {
      describe("hello(\"world\", cb)", function() {
        it('should return "hello world"', function() {
          let cb = sinon.spy();
          hello("world", cb);
          cb.should.have.been.calledWith("hello world");
        });
      });
    });
    \`\`\`

* [async](https://www.npmjs.com/package/async), [request](https://www.npmjs.com/package/request), [isomorphic-fetch](https://www.npmjs.com/package/isomorphic-fetch) and [axios](https://www.npmjs.com/package/axios) for making async HTTP requests.

* [q](https://www.npmjs.com/package/q) and [bluebird](https://www.npmjs.com/package/bluebird) are promise libraries.

* [jsdom](https://www.npmjs.com/package/jsdom) is a library that emulates an HTML DOM moment within the NodeJS environment.

* [moment](https://www.npmjs.com/package/moment) is a library for parsing, validating, manipulating, and formatting dates.

Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  },
  // Kotlin: {
  //   name: "kotlin",
  //   boilerplate:
  //     '// To execute your Kotlin program, please define a top level function named main\n\nfun main(args: Array<String>) {\n  for (i in 1..5) println("Hello, World!")\n}',
  //   comments: {
  //     type: "single",
  //     prefix: "//",
  //     suffix: null
  //   }
  // },
  // OCaml: {
  //   name: "ocaml",
  //   boilerplate:
  //     'let rec hello n =\n  match n with\n  | 0 -> ()\n  | _ -> print_endline "Hello, World!"; hello (n-1);;\n\nhello 5;;',
  //   comments: {
  //     type: "multi",
  //     prefix: "(*",
  //     suffix: "*)"
  //   }
  // },
  // Perl: {
  //   name: "perl",
  //   boilerplate:
  //     'use strict;\nuse warnings;\nuse Data::Dumper;\n\nfor (my $count = 5; $count >= 1; $count--) {\n  print "Hello, World #$count\n";\n}',
  //   comments: {
  //     type: "single",
  //     prefix: "#",
  //     suffix: null
  //   }
  // },
  PHP: {
    title: {
      header: "PHP",
      subheader: "Running Zend PHP v7.2"
    },
    body: `You must wrap your code in <?php and ?> tags. 
    
  We have also installed [PHPUnit 8.3](https://phpunit.de/).

  When you run the <MarkdownCode>test.php</MarkdownCode> file, it is invoked with PHPUnit.
    
  Here’s a quick example of how to use PHPUnit:

  \`\`\`php
  <?php
  declare(strict_types=1);
  require_once('solution.php');
  
  use PHPUnitFrameworkTestCase;
  
  final class HelloTest extends TestCase
  {
    public function testCanBeUsedAsString(): void
    {
      $this->assertEquals(
        'Hello, World',
          Hello::toString()
        );
    }
  }
  ?>
  \`\`\`

  Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  },
  Python2: {
    title: {
      header: "Python2",
      subheader: "Running Python2.7"
    },
    body: `You will be able to use Python's standard library and it's built-in test framework, [unittest](https://docs.python.org/2/library/unittest.html).
    
  Here’s a quick example of how to use unittest:

  \`\`\`python
  import unittest
  from solution import hello

  class TestHello(unittest.TestCase):

    def test_hello(self):
      self.assertEqual( hello(), "Hello, World")

  if __name__ == '__main__':
    unittest.main()
  \`\`\`

  Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  },
  Python3: {
    title: {
      header: "Python3",
      subheader: "Running Python 3.6"
    },
    body: `Python3 evironment is generally identical to the Python2 environment.
    
  You will be able to use Python's standard library and it's built-in test framework, [unittest](https://docs.python.org/3.6/library/unittest.html).
    
  Here’s a quick example of how to use unittest:
  
  \`\`\`python
  import unittest
  from solution import hello
  
  class TestHello(unittest.TestCase):

    def test_hello(self):
      self.assertEqual( hello(), "Hello, World")
  
  if __name__ == '__main__':
    unittest.main()
  \`\`\`
  
  Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  },
  // R: {
  //   compiler: "r",
  //   boilerplate: 'for(i in 1:5) {\n  print(paste("Hello, World"))\n}',
  //   comments: {
  //     type: "single",
  //     prefix: "#",
  //     suffix: null
  //   }
  // },
  Ruby: {
    title: {
      header: "Ruby",
      subheader: "Running Ruby 2.6"
    },
    body: `We have installed the following libraries:
  
  * [minitest](https://github.com/seattlerb/minitest) is a testing framework that supports TDD, BDD, mocking, and benchmarking.

    Here’s a quick example of how to use minitest:
    
    \`\`\`ruby
    require 'minitest/autorun'
    require_relative 'solution'

    describe Hello do
      it 'must print "Hello, World"' do
        Hello.new.toString.must_equal 'Hello, World'
      end
    end
    \`\`\`

  * [RSpec](http://rspec.info/) is another popular testing framework.

    Here’s a quick example of how to use RSpec:
    
    \`\`\`ruby
    require 'rspec/autorun'
    require_relative 'solution'

    RSpec.describe Hello do
      it 'must print "Hello, World"' do
        expect(Hello.new.toString).to  eq('Hello, World')
      end
    end
    \`\`\`

  Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  },
  // Rust: {
  //   name: "rust",
  //   boilerplate:
  //     'fn main() {\n  for n in 0..5 {\n    println!("{} - Hello, World!", n);\n  }\n}',
  //   comments: {
  //     type: "single",
  //     prefix: "//",
  //     suffix: null
  //   }
  // },
  Scala: {
    title: {
      header: "Scala",
      subheader: "Running Scala 2.12"
    },
    body: `In the <MarkdownCode>Solution.scala</MarkdownCode> file, you should define an object named <MarkdownCode>Solution</MarkdownCode> that extends <MarkdownCode>App</MarkdownCode>.

  \`\`\`scala
  object Solution extends App {
    for (i <- 0 until 5) println("Hello, World!")
  }
  \`\`\`

  Alternatively, you can forego using the App trait helper and define main yourself:

  \`\`\`scala
  object Solution {
    def main(args: Array[String]) {
      println("Hello, World!")
    }
  }
  \`\`\`

  ## Testing

  For the <MarkdownCode>SolutionSpec.scala</MarkdownCode> file, We have made the following libraries available for your use:

  * [ScalaTest](http://www.scalatest.org/) is a popular testing framework.
    
    Here is a quick example of using ScalaTest:

    \`\`\`scala
    import org.scalatest._

    class SolutionSpec extends FlatSpec with Matchers {
      "The Hello object" should "say Hello, World!" in {
        Solution.greeting shouldEqual "Hello, World!"
      }
    }
    \`\`\`

  * [ScalaCheck](https://www.scalacheck.org/) is used for automated property-based testing.

  Are there any libraries or settings we missed? Feel free to [email us](mailto:support@codertest.io?subject=CoderTest%20Language%20Suggestion) with suggestions!`
  }
  // Swift: {
  //   name: "swift",
  //   boilerplate:
  //     'import Foundation\n\nfor _ in 1...5 {\n  print("Hello, World!")\n}',
  //   comments: {
  //     type: "single",
  //     prefix: "//",
  //     suffix: null
  //   }
  // },
  // "Visual Basic": {
  //   name: "vb",
  //   boilerplate:
  //     '\' To execute your Visual Basic program, please define a "Sub Main"\n\' on the top level Module\n\nModule Solution\n    Sub Main()\n        Console.WriteLine ("Hello, World")\n    End Sub\nEnd Module',
  //   comments: {
  //     type: "single",
  //     prefix: "'",
  //     suffix: null
  //   }
  // }
};
