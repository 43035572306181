import React from "react";
import { Spin } from "antd";

const Loading = ({ height }) => (
  <div
    style={{
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      height
    }}
  >
    <Spin size="large" />
  </div>
);

export default Loading;
