import React, { Component } from "react";
import {
  Row,
  Col,
  Icon,
  Table,
  Divider,
  Card,
  Input,
  Dropdown,
  Menu,
  Button,
  Popconfirm,
  Tabs
} from "antd";
import PageHeader from "ant-design-pro/lib/PageHeader";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import moment from "moment";
import escapeRegExp from "lodash.escaperegexp";

import CodeMirrorEditor from "../Editor/CodeMirrorEditor";
import QuestionModal from "./AddQuestionModal";
import ImportQuestions from "./ImportQuestions";

import { languages } from "../../constants";

import "./styles.css";

import { fetchQuestions, deleteQuestion } from "../../actions";

const { Search } = Input;
const { TabPane } = Tabs;

class Questions extends Component {
  state = {
    sort: "Select..",
    questionData: [],
    searchValue: ""
  };

  componentDidMount() {
    const { type } = this.props;

    this.props.fetchQuestions(type);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.questions, prevProps.questions)) {
      let { data } = this.props.questions;
      this.setState({ questionData: data });
    }
  }

  renderFiles = record => {
    const { solution, test, name } = languages[record.language];
    return (
      <Tabs
        defaultActiveKey="1"
        onChange={() => {}}
        className="questions-modal"
      >
        <TabPane tab={solution.sourceFile} key="1">
          <CodeMirrorEditor
            language={name}
            code={record.question.solution}
            lineNumbers={false}
            readOnly={"nocursor"}
          />
        </TabPane>
        {test && test.testable ? (
          <TabPane tab={test.sourceFile} key="2">
            <CodeMirrorEditor
              language={name}
              code={record.question.test}
              lineNumbers={false}
              readOnly={"nocursor"}
            />
          </TabPane>
        ) : null}
      </Tabs>
    );
  };

  render() {
    const { sort, questionData } = this.state;
    const { questions } = this.props;
    console.log({ questions });
    const columns = [
      {
        title: null,
        key: "title",
        render: (text, record) => (
          <div>
            <h4 style={{ margin: 0 }}>{record.title}</h4>
            <div>{record.description}</div>
            <div>{record.language}</div>
          </div>
        )
      },
      {
        title: null,
        render: (text, record) => {
          return (
            <span>
              <QuestionModal type="edit" record={record} />
            </span>
          );
        }
      }
    ];

    return (
      <React.Fragment>
        <div style={{ margin: "-24px -24px 0" }}>
          <PageHeader
            key="pageheader"
            title="Questions"
            action={
              <div style={{ display: "flex" }}>
                <QuestionModal type="add" />
                {/* <div style={{ paddingLeft: "10px" }}>
                  <ImportQuestions />
                </div> */}
              </div>
            }
          />
        </div>
        <Row
          gutter={24}
          style={{ margin: "24px auto 0px auto", maxWidth: "1000px" }}
        >
          <Col span={24}>
            <Card>
              <div style={{ display: "flex" }}>
                <Search
                  placeholder="Search questions..."
                  onSearch={value => {
                    let regexValue = escapeRegExp(value);
                    this.setState({
                      searchValue: value,
                      questionData: this.props.questions.data.filter(
                        item =>
                          item.language.search(new RegExp(regexValue, "i")) !==
                            -1 ||
                          item.title.search(new RegExp(regexValue, "i")) !==
                            -1 ||
                          item.description.search(
                            new RegExp(regexValue, "i")
                          ) !== -1 ||
                          item.question.solution.search(
                            new RegExp(regexValue, "i")
                          ) !== -1 ||
                          item.question.test.search(
                            new RegExp(regexValue, "i")
                          ) !== -1
                      )
                    });
                  }}
                  style={{ flex: 1 }}
                  enterButton
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Row
          gutter={24}
          style={{ margin: "24px auto 0px auto", maxWidth: "1000px" }}
        >
          <Col span={24}>
            <Card>
              <Table
                columns={columns}
                dataSource={questionData}
                showHeader={true}
                rowKey={record => record._id}
                expandedRowRender={this.renderFiles}
                expandRowByClick
                expandIconAsCell={false}
                showHeader={false}
                pagination={false}
                rowClassName="pointerCursor"
              />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ questions }) => ({
  questions
});

export default connect(
  mapStateToProps,
  { fetchQuestions, deleteQuestion }
)(Questions);
