import React from "react";
import { Button, Select, Icon, Tabs, Drawer } from "antd";
import isEmpty from "lodash.isempty";
import { connect } from "react-redux";
import { InlineMath, BlockMath } from "react-katex";

import { runTests } from "../../actions";

import CodeEditor from "../Editor/CodeMirrorEditor";
import LanguageSelect from "../Editor/LanguageSelect";
import Terminal from "../Editor/Terminal";
import Markdown from "../UI/MarkdownJSX";

import { languages, socketTypes } from "../../constants";
import { generateCodeChallengeTerminalLines } from "../Editor/utils";

import SocketContext from "../../socket-context";

import "katex/dist/katex.min.css";
import "./styles.css";

const {
  FETCH_CODE_CHALLENGE_QUESTIONS,
  RUN_CODE_CHALLENGE_TESTS,
  RUN_CODE_CHALLENGE_SOLUTION,
  CODE_CHALLENGE_TERMINAL_CHANGE
} = socketTypes;

const { TabPane } = Tabs;

const testcases = [
  { _id: 1, success: true },
  { _id: 2, success: false },
  { _id: 3, success: false }
];
class ChallengeScreen extends React.Component {
  state = {
    solution_code: "",
    test_code: "",
    terminal: [],
    language: "JavaScript",
    drawerVisible: false,
    questions: [],
    selectedQuestionIndex: null,
    tests: {},
    activeFile: "solution.js"
  };

  componentDidMount = () => {
    const room = this.props.match.params._id;
    const { socket } = this.props;
    socket.on("connect", () => {
      console.log("connected: ", socket.id);
      socket.emit(FETCH_CODE_CHALLENGE_QUESTIONS, room);
      console.log("connected to room: ", room);
    });
    socket.on(FETCH_CODE_CHALLENGE_QUESTIONS, data => {
      console.log({ data });
      if (data.success) {
        console.log({ languages });
        this.setState({
          solution_code: languages["JavaScript"].solution.boilerplate,
          test_code: languages["JavaScript"].test.boilerplate,
          questions: data.interview.selectedQuestions,
          selectedQuestionIndex: 0,
          activeFile: languages["JavaScript"].solution.sourceFile
        });
      }
    });

    socket.on(RUN_CODE_CHALLENGE_TESTS, data => {
      console.log({ data });
      if (data.success) {
        let { id, tests } = data;
        let testsObj = JSON.parse(JSON.stringify(this.state.tests));
        testsObj[id] = tests;
        this.setState({ tests: testsObj });
      }
    });

    socket.on(CODE_CHALLENGE_TERMINAL_CHANGE, data => {
      console.log("terminal: ", data);
      let { lines, language, message } = data;
      let output = {
        lines,
        language,
        ...message
      };
      this.setState({ terminal: [...this.state.terminal, output] });
    });
    // socket.on(RESET_CODE, ({ id }) => {
    //   let user = this.state.participants.find(user => user.id === id);
    //   let info = [user.id, `${user.username}`, ` reset the code.`];
    //   let data = [info, ">"];
    //   this.setState({ terminal: [...this.state.terminal, ...data] });
    // });

    // socket.on(END_INTERVIEW, () => {
    //   console.log("Incoming END_INTERVIEW", this.props);
    //   const { auth, history } = this.props;
    //   if (!isEmpty(auth.data) && !auth.loading) {
    //     history.push(`/room/${room}/playback`);
    //   } else {
    //     history.push(`/room/${room}/thanks`);
    //   }
    // });
  };

  componentWillUnmount() {
    this.props.socket.emit("disconnect");
  }

  handleLanguageChange = value => {
    this.appendToEditor(languages[value].boilerplate, value);
  };

  appendToEditor = (code, language) => {
    if (language === this.state.language) {
      this.setState({ code: `${this.state.code}\n${code}` });
    } else {
      const { comments } = languages[language];
      if (this.state.code === "") {
        this.setState({ code, language });
      } else {
        let splitCode = this.state.code.split("\n");
        let commentedCode;
        if (comments.type === "single") {
          commentedCode = [
            "",
            `Your previous ${this.state.language} content is preserved below:`,
            ...splitCode
          ]
            .map(line => `${comments.prefix} ${line}`)
            .join("\n");
        } else {
          commentedCode = [
            comments.prefix,
            `Your previous ${this.state.language} content is preserved below:`,
            ...splitCode,
            comments.suffix
          ].join("\n");
        }
        this.setState({ code: `${code}\n\n\n${commentedCode}`, language });
      }
    }
  };

  onSolutionEditorChange = (editor, data, value) => {
    this.setState({ solution_code: value });
  };

  onTestEditorChange = (editor, data, value) => {
    this.setState({ test_code: value });
  };

  showDrawer = () => {
    this.setState({
      drawerVisible: true
    });
  };

  onDrawerClose = () => {
    this.setState({
      drawerVisible: false
    });
  };

  nextQuestion = index => {
    this.setState({ selectedQuestionIndex: index });
  };

  onSubmit = () => {
    const { solution_code, test_code, language, activeFile } = this.state;
    if (activeFile === languages[language].solution.sourceFile) {
      console.log(this.props);
      this.props.socket.emit(RUN_CODE_CHALLENGE_SOLUTION, {
        solution_code,
        test_code: "",
        language: languages[language].name
      });
    } else {
      this.props.socket.emit(RUN_CODE_CHALLENGE_SOLUTION, {
        solution_code,
        test_code,
        language: languages[language].name
      });
    }
  };

  onRunTests = () => {
    const {
      solution_code,
      language,
      questions,
      selectedQuestionIndex
    } = this.state;
    this.props.socket.emit(RUN_CODE_CHALLENGE_TESTS, {
      solution_code,
      language: languages[language].name,
      id: questions[selectedQuestionIndex]._id
    });
  };

  render() {
    const {
      language,
      solution_code,
      test_code,
      terminal,
      questions,
      selectedQuestionIndex,
      activeFile
    } = this.state;
    const operations = [
      <Button
        className="ide-btn"
        style={{ marginRight: "30px" }}
        onClick={this.onRunTests}
      >
        Run Challenge Tests
      </Button>,

      <Button className="ide-btn" onClick={this.showDrawer}>
        <Icon type="menu" />
      </Button>
    ];
    if (!questions.length || selectedQuestionIndex === null) {
      return null;
    }
    return (
      <React.Fragment>
        <Drawer
          title="Questions"
          placement="right"
          closable={false}
          onClose={this.onDrawerClose}
          visible={this.state.drawerVisible}
        >
          {questions.map((q, i) => {
            return (
              <div
                key={q._id}
                onClick={() => this.nextQuestion(i)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  border: i === selectedQuestionIndex ? "1px solid grey" : "",
                  padding: "10px",
                  marginBottom: "1em",
                  borderRadius: "4px",
                  cursor: "pointer"
                }}
              >
                {q.title}
              </div>
            );
          })}
          <Button>Submit CodeChallenge</Button>
        </Drawer>

        <div
          style={{ height: "100vh", backgroundColor: "rgba(23, 27, 33, 0.96)" }}
        >
          <div
            style={{
              display: "flex"
            }}
          >
            <div style={{ width: "50%", padding: "8px 4px 8px 8px" }}>
              <div
                style={{
                  display: "flex",
                  padding: "10px 10px 16px",
                  justifyContent: "space-between",
                  background: "#181C22"
                }}
              >
                <Button
                  type="primary"
                  style={{ backgroundColor: "#08979c", borderColor: "#08979c" }}
                  onClick={this.onSubmit}
                >
                  Run
                  <Icon type="arrow-right" />
                </Button>
                <Button.Group style={{ display: "flex" }}>
                  {/* <Button className="ide-btn">Info</Button> */}
                  <LanguageSelect
                    handleLanguageChange={this.handleLanguageChange}
                    language={language}
                  />
                </Button.Group>
              </div>
              <Tabs
                defaultActiveKey={activeFile}
                onChange={file => this.setState({ activeFile: file })}
                tabBarStyle={{
                  background: "#181C22",
                  margin: 0,
                  padding: "0 10px 16px",
                  border: "none"
                }}
              >
                <TabPane
                  tab={languages[language].solution.sourceFile}
                  key={languages[language].solution.sourceFile}
                >
                  <div
                    style={{
                      padding: "0px 10px",
                      background: "#181C22",
                      height: "81.7vh"
                    }}
                  >
                    <CodeEditor
                      language={languages[language].name}
                      theme={"monokai"}
                      lineNumbers={true}
                      code={solution_code}
                      onEditorChange={this.onSolutionEditorChange}
                    />
                  </div>
                </TabPane>
                <TabPane
                  tab={languages[language].test.sourceFile}
                  key={languages[language].test.sourceFile}
                >
                  <div
                    style={{
                      padding: "0px 10px",
                      background: "#181C22",
                      height: "81.7vh"
                    }}
                  >
                    <CodeEditor
                      language={languages[language].name}
                      theme={"monokai"}
                      lineNumbers={true}
                      code={test_code}
                      onEditorChange={this.onTestEditorChange}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
            <div style={{ width: "50%", padding: "8px 8px 8px 4px" }}>
              <Tabs
                defaultActiveKey="1"
                onChange={() => {}}
                tabBarExtraContent={operations}
                tabBarStyle={{
                  background: "#181C22",
                  margin: 0,
                  padding: "0 10px 16px"
                }}
              >
                <TabPane tab="Question" key="1">
                  <div
                    style={{
                      // fontFamily: `"Roboto Mono", Menlo, "Ubuntu Mono", Monaco, Consolas,
                      // "source-code-pro", monospace`,
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "white",
                      background: "rgba(23, 27, 33)",
                      height: "88vh",
                      overflow: "auto",
                      padding: "20px 10px 0px"
                    }}
                  >
                    <Markdown
                      children={questions[selectedQuestionIndex].question}
                      style={{ color: "white" }}
                      options={{
                        namedCodesToUnicode: {
                          le: "\u2264",
                          ge: "\u2265"
                        },
                        overrides: {
                          h3: {
                            props: {
                              style: { color: "white" }
                            }
                          },
                          pre: {
                            props: {
                              className: "question-markdown-pre"
                            }
                          },
                          InlineMath: {
                            component: InlineMath
                          }
                        }
                      }}
                    />
                  </div>
                </TabPane>
                <TabPane tab="Output" key="2">
                  <Terminal
                    terminal={generateCodeChallengeTerminalLines(terminal)}
                    height="88vh"
                  />
                </TabPane>
                <TabPane tab="Tests" key="3">
                  <div
                    style={{
                      background: "rgba(23, 27, 33)",
                      height: "88vh",
                      overflow: "auto",
                      padding: "20px 10px 0px"
                    }}
                  >
                    {testcases.map((testcase, i) => (
                      <Button
                        key={testcase._id}
                        className={"ide-btn"}
                        style={{
                          color: testcase.success ? "#389e0d " : "#cf1322",
                          borderColor: testcase.success
                            ? "#389e0d "
                            : "#cf1322",
                          marginRight: "10px"
                        }}
                      >
                        {i + 1}
                      </Button>
                    ))}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              padding: "0 10px"
              // justifyContent: "space-between"
            }}
          >
            <Button
              className={"ide-btn"}
              style={{
                color: "#389e0d",
                borderColor: "#389e0d",
                marginLeft: "auto"
              }}
              onClick={() => {}}
            >
              Submit Your Answer
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const ChallengeScreenConxtext = props => (
  <SocketContext.Consumer>
    {socket => <ChallengeScreen socket={socket} {...props} />}
  </SocketContext.Consumer>
);

const mapStateToProps = ({ tests }) => ({
  tests
});

export default connect(
  mapStateToProps,
  { runTests }
)(ChallengeScreenConxtext);
