import React from "react";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";

const TopMenu = ({ menuKey, changeMenuKey }) => {
  return (
    <Menu
      mode="horizontal"
      theme="dark"
      selectedKeys={[menuKey]}
      style={{ padding: "2vh" }}
    >
      <Menu.Item key="1" className="top-menu">
        <Link
          to="/coderoom/interviews"
          onClick={() => {
            changeMenuKey("top", "1");
            changeMenuKey("side", "3");
          }}
        >
          <span>CodeRoom</span>
        </Link>
      </Menu.Item>
      {process.env.NODE_ENV === "development" ? (
        <Menu.Item key="2" className="top-menu">
          <Link
            to="/codechallenge/interviews"
            onClick={() => {
              changeMenuKey("top", "2");
              changeMenuKey("side", "5");
            }}
          >
            <span>CodeChallenge</span>
          </Link>
        </Menu.Item>
      ) : null}
    </Menu>
  );
};

export default TopMenu;
