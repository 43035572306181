import {
  FETCH_PLANS_STARTED,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_FAILURE,
  FETCH_SUBSCRIPTION_STARTED,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILURE,
  CREATE_SUBSCRIPTION_STARTED,
  CREATE_SUBSCRIPTION_FAILURE,
  DELETE_SUBSCRIPTION_STARTED,
  DELETE_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_STARTED,
  UPDATE_SUBSCRIPTION_FAILURE,
  FETCH_INVOICE_STARTED,
  FETCH_INVOICE_SUCCESS,
  FETCH_INVOICE_FAILURE
} from "../actions/types";

const initialPlansState = {
  loading: false,
  data: [],
  error: null
};

export const plans = (state = initialPlansState, action) => {
  switch (action.type) {
    case FETCH_PLANS_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_PLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_PLANS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const initialSubscriptionState = {
  loading: false,
  data: {},
  error: null
};

export const subscription = (state = initialSubscriptionState, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_SUBSCRIPTION_STARTED:
      return {
        ...state,
        loading: true
      };
    case CREATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DELETE_SUBSCRIPTION_STARTED:
      return {
        ...state,
        loading: true
      };
    case DELETE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_SUBSCRIPTION_STARTED:
      return {
        ...state,
        loading: true
      };
    case UPDATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const initialInvoiceState = {
  loading: false,
  data: {},
  error: null
};

export const invoice = (state = initialInvoiceState, action) => {
  switch (action.type) {
    case FETCH_INVOICE_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
