import {
  FETCH_QUESTIONS_STARTED,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILURE,
  CREATE_QUESTIONS_STARTED,
  CREATE_QUESTIONS_FAILURE,
  DELETE_QUESTIONS_STARTED,
  DELETE_QUESTIONS_FAILURE,
  UPDATE_QUESTIONS_STARTED,
  UPDATE_QUESTIONS_FAILURE,
  RUN_TESTS_STARTED,
  RUN_TESTS_SUCCESS,
  RUN_TESTS_FAILURE
} from "../actions/types";

const initialQuestionsState = {
  loading: false,
  data: [],
  error: null
};

export const questions = (state = initialQuestionsState, action) => {
  switch (action.type) {
    case FETCH_QUESTIONS_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_QUESTIONS_STARTED:
      return {
        ...state,
        loading: true
      };
    case CREATE_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DELETE_QUESTIONS_STARTED:
      return {
        ...state,
        loading: true
      };
    case DELETE_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_QUESTIONS_STARTED:
      return {
        ...state,
        loading: true
      };
    case UPDATE_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const initialTestsState = {
  loading: false,
  data: [],
  error: null
};

export const tests = (state = initialTestsState, action) => {
  switch (action.type) {
    case RUN_TESTS_STARTED:
      return {
        ...state,
        loading: true
      };
    case RUN_TESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case RUN_TESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
