import React, { Component } from "react";
import { Modal, Button, Input } from "antd";
const Search = Input.Search;

class EndInterviewModal extends Component {
  render() {
    return (
      <React.Fragment>
        <Button
          className={"ide-btn end-interview"}
          style={{
            color: "#f5222d",
            borderColor: "#f5222d"
          }}
          onClick={this.props.handleEndInterviewButtonClick}
        >
          End Interview
        </Button>
        <Modal
          title="Exit Interview?"
          visible={this.props.visible}
          closable={false}
          onCancel={this.props.handleEndInterviewButtonClick}
          footer={[
            <Button
              key="back"
              onClick={this.props.handleEndInterviewButtonClick}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="danger"
              onClick={this.props.handleEndInterview}
            >
              End Interview
            </Button>
          ]}
        >
          <p>
            Ending the interview will redirect interviewees to a "Thank You"
            page and make this interview accessible only by you and your
            organization.
          </p>
          <p>
            You'll also be able to review and refine your notes.
          </p>
        </Modal>
      </React.Fragment>
    );
  }
}

export default EndInterviewModal;
