import React, { Component } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";

import "codemirror/mode/markdown/markdown";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";

class MarkdownNotes extends Component {
  render() {
    const { value, onMarkdownEditorChange } = this.props;
    return (
      <CodeMirror
        value={value}
        options={{
          mode: "markdown",
          theme: "monokai",
          lineNumbers: false
        }}
        onBeforeChange={onMarkdownEditorChange}
      />
    );
  }
}
export default MarkdownNotes;
