import React, { Component } from "react";
import PageHeader from "ant-design-pro/lib/PageHeader";
import {
  StripeProvider,
  Elements,
  CardElement,
  injectStripe
} from "react-stripe-elements";
import { Row, Col, Card } from "antd";
import { connect } from "react-redux";
import {
  fetchPlans,
  fetchSubscription,
  deleteSubscription,
  createSubscription,
  updatePaymentSource
} from "../../../actions";

import InjectedPayment from "./InjectedPayment";
import "./styles.css";

class Payment extends Component {
  state = {
    stripe: null
  };

  componentDidMount() {
    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
      });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({
          stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
        });
      });
    }
  }

  render() {
    const { planName, type } = this.props;
    return (
      <React.Fragment>
        <StripeProvider stripe={this.state.stripe}>
          <Elements>
            <InjectedPayment planName={planName} type={type} />
          </Elements>
        </StripeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth, plans, subscription }) => ({
  auth,
  plans,
  subscription
});

export default connect(
  mapStateToProps,
  {
    fetchPlans,
    fetchSubscription,
    deleteSubscription,
    createSubscription,
    updatePaymentSource
  }
)(Payment);

const subscribedPlan = (plans, id) => {
  if (id) {
    return plans.data.find(plan => plan.stripe.id === id);
  }
  return null;
};
