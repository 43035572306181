import axios from "axios";
import {
  TOUR_START,
  TOUR_NEXT,
  TOUR_STOP,
  UPDATE_TOUR_STARTED,
  UPDATE_TOUR_FAILURE
} from "./types";
import { fetchUser } from "./index";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const startTour = () => async dispatch =>
  dispatch({ type: TOUR_START, payload: { type: "START" } });

export const endTour = () => async dispatch =>
  dispatch({ type: TOUR_STOP, payload: { type: "STOP" } });

export const nextTourStep = () => async dispatch =>
  dispatch({ type: TOUR_NEXT, payload: { type: "NEXT" } });

export const updateTourStatus = (
  completed,
  skipped,
  callback = () => {}
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_TOUR_STARTED });
    await axios.put(
      `${API_BASE_URL}/api/v1/tour`,
      {
        completed,
        skipped
      },
      { withCredentials: true }
    );
    dispatch(fetchUser());
    callback();
  } catch (error) {
    dispatch({ type: UPDATE_TOUR_FAILURE, payload: error.response.data });
  }
};
