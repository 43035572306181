import React from "react";
import { Button, Modal, Input, Tabs } from "antd";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";

import escapeRegExp from "lodash.escaperegexp";
import CodeMirrorEditor from "./CodeMirrorEditor";

import { fetchQuestions } from "../../actions";

import { languages } from "../../constants";

const { Search } = Input;
const { TabPane } = Tabs;

class QuestionsModal extends React.Component {
  state = {
    visible: false,
    questionData: [],
    selected: null
  };

  componentDidMount() {
    this.props.fetchQuestions();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.questions, prevProps.questions)) {
      let { data } = this.props.questions;
      let selected = data.length > 0 ? 0 : null;
      this.setState({ questionData: data, selected });
    }
  }

  handleBtnClick = () => this.setState({ visible: !this.state.visible });

  handleQuestionClick = index => this.setState({ selected: index });

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  replaceEditor = () => {
    const { questionData, selected } = this.state;
    const {
      language,
      question: { solution, test }
    } = questionData[selected];

    this.props.replaceEditor(language, solution, test);
    this.setState({ visible: false });
  };

  appendToEditor = () => {
    const { questionData, selected } = this.state;
    const {
      language,
      question: { solution, test }
    } = questionData[selected];

    this.props.appendToEditor(language, solution, test);
    this.setState({ visible: false });
  };

  handleSearch = value => {
    let regexValue = escapeRegExp(value);
    this.setState({
      searchValue: value,
      questionData: this.props.questions.data.filter(
        item =>
          item.language.search(new RegExp(regexValue, "i")) !== -1 ||
          item.title.search(new RegExp(regexValue, "i")) !== -1 ||
          item.description.search(new RegExp(regexValue, "i")) !== -1 ||
          item.question.solution.search(new RegExp(regexValue, "i")) !== -1 ||
          item.question.test.search(new RegExp(regexValue, "i")) !== -1
      )
    });
  };

  renderFiles = () => {
    const { questionData, selected } = this.state;
    const { solution, test, name } = languages[questionData[selected].language];
    return (
      <Tabs
        defaultActiveKey="1"
        onChange={() => {}}
        className="questions-modal"
      >
        <TabPane tab={solution.sourceFile} key="1">
          <CodeMirrorEditor
            language={name}
            code={questionData[selected].question.solution}
            lineNumbers={false}
            readOnly={"nocursor"}
          />
        </TabPane>
        {test && test.testable ? (
          <TabPane tab={test.sourceFile} key="2">
            <CodeMirrorEditor
              language={name}
              code={questionData[selected].question.test}
              lineNumbers={false}
              readOnly={"nocursor"}
            />
          </TabPane>
        ) : null}
      </Tabs>
    );
  };
  render() {
    const { questionData, visible, selected } = this.state;
    console.log({ questionData, selected });
    return (
      <React.Fragment>
        <Button className="ide-btn" onClick={this.handleBtnClick}>
          Questions
        </Button>
        <Modal
          title="Questions"
          visible={visible}
          bodyStyle={{ height: "60vh" }}
          width={"60vw"}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="replace" type="primary" onClick={this.replaceEditor}>
              Replace Editor
            </Button>,
            <Button key="append" type="primary" onClick={this.appendToEditor}>
              Append to Editor
            </Button>
          ]}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <div
              style={{
                width: "50%",
                borderRight: "1px solid rgba(191, 191, 191, 0.85)",
                paddingRight: "10px"
              }}
            >
              <Search
                placeholder="Search questions..."
                onSearch={this.handleSearch}
                style={{ flex: 1, paddingBottom: "10px" }}
                enterButton
              />
              {questionData.map((record, i) => (
                <div key={record._id} style={{ paddingBottom: "10px" }}>
                  <div
                    style={{
                      background: `${
                        selected === i ? "rgba(199, 209, 217, 0.2)" : "white"
                      }`,
                      border: "1px solid rgba(191, 191, 191, 0.85)",
                      padding: "10px",
                      cursor: "pointer"
                    }}
                    onClick={() => this.handleQuestionClick(i)}
                  >
                    <h4 style={{ margin: 0 }}>{record.title}</h4>
                    <div>{record.description}</div>
                    <div>{record.language}</div>
                  </div>
                </div>
              ))}
            </div>
            <div style={{ width: "100%", maxWidth: "36vw" }}>
              {selected !== null && questionData[selected] ? (
                <React.Fragment>
                  <div
                    style={{
                      borderBottom: "1px solid rgba(191, 191, 191, 0.85)",
                      padding: "0 10px 10px 10px"
                    }}
                  >
                    <h4 style={{ margin: 0 }}>
                      {questionData[selected].title}
                    </h4>
                    <div>{questionData[selected].description}</div>
                    <div>{questionData[selected].language}</div>
                  </div>
                  <div style={{ padding: "10px" }}>{this.renderFiles()}</div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ questions }) => ({
  questions
});

export default connect(
  mapStateToProps,
  { fetchQuestions }
)(QuestionsModal);
