import React, { Component } from "react";
import { Modal, Button, Input } from "antd";
const Search = Input.Search;

class NameModal extends Component {
  handleOk = name => {
    this.props.handleParticipants(name);
  };

  render() {
    return (
      <Modal
        title={null}
        visible={this.props.visible}
        closable={false}
        footer={null}
      >
        <p>
          Let us know what your name is and we'll get your interview started.
        </p>
        <Search
          placeholder="Name"
          name="name"
          enterButton="GO"
          onSearch={this.handleOk}
        />
      </Modal>
    );
  }
}

export default NameModal;
