import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import { Button, Select, Icon, Tabs, Spin } from "antd";

import "./styles.css";

import CodeEditor from "./CodeMirrorEditor";
import Terminal from "./Terminal";
import LanguageSelect from "./LanguageSelect";
import NameModal from "./NameModal";
import MarkdownNotes from "./MarkdownNotes";
import EndInterviewModal from "./EndInterviewModal";
import InviteButton from "./InviteButton";
import QuestionsModal from "./QuestionsModal";
import LanguageInfoModal from "./LanguageInfoModal";
import Tour from "../Tour";

import SocketContext from "../../socket-context";

import { fetchUser } from "../../actions";

import { socketTypes, languages } from "../../constants";
import { generateCodeRoomTerminalLines } from "./utils";
import { hideChat } from "../../chat.js";

const { TabPane } = Tabs;

const {
  CHANGE_CODE_ROOM_SOLUTION,
  ADD_CODE_ROOM_USER,
  CODE_ROOM_USER_JOINED,
  CODE_ROOM_USER_LEFT,
  ENTER_CODE_ROOM,
  CODE_ROOM_TERMINAL_CHANGE,
  RUN_CODE_ROOM_SOLUTION,
  FINISH_CODE_ROOM_INTERVIEW,
  SAVE_CODE_ROOM_INTERVIEWER_NOTES,
  SAVE_CODE_ROOM_SNAPSHOT
} = socketTypes;

const { Option } = Select;

const colors = [
  "#f5222d",
  "#1890ff",
  "#52c41a",
  "#fa8c16",
  "#faad14",
  "#722ed1",
  "#eb2f96",
  "#fa541c"
];

class IDE extends Component {
  state = {
    solution_code: "",
    test_code: "",
    terminal: [],
    language: "JavaScript",
    socketId: "",
    participants: [],
    showParticipantModal: false, // true,
    showEndInterviewModal: false,
    markdownNotes: "",
    showThankYouPage: false,
    interviewOver: false,
    activeFile: "solution.js",
    running_code: false
  };

  // method for emitting a socket.io event
  sendCode = () => {
    const {
      solution_code,
      test_code,
      terminal,
      language,
      activeFile
    } = this.state;
    this.props.socket.emit(CHANGE_CODE_ROOM_SOLUTION, {
      solution_code,
      test_code,
      language,
      output: terminal,
      activeFile
    });
  };

  onMarkdownEditorChange = (editor, data, value) =>
    this.setState(
      {
        markdownNotes: value
      },
      () =>
        this.props.socket.emit(SAVE_CODE_ROOM_INTERVIEWER_NOTES, {
          notes: value
        })
    );

  onSolutionEditorChange = (editor, data, value) => {
    this.setState({ solution_code: value }, () => this.sendCode());
  };

  onTestEditorChange = (editor, data, value) => {
    this.setState({ test_code: value }, () => this.sendCode());
  };

  componentDidMount = () => {
    const room = this.props.match.params._id;
    const { socket } = this.props;
    console.log("componentDidMount", this.props);
    console.log("socket: ", socket.connected);

    hideChat(window);

    if (socket.connected) {
      socket.emit(ENTER_CODE_ROOM, room);
      console.log("connected to room: ", room);
      this.props.fetchUser();
    } else if (!socket.connected) {
      socket.on("connect", () => {
        console.log("connected: ", socket.id);

        socket.emit(ENTER_CODE_ROOM, room);
        console.log("connected to room: ", room);
      });
    }
    socket.on(ENTER_CODE_ROOM, data => {
      console.log({ data });
      if (data.success) {
        if (data.latest) {
          this.setState(
            {
              solution_code: data.latest.solution_code || "",
              test_code: data.latest.test_code || "",
              terminal: data.latest.output,
              language: data.latest.language,
              activeFile: languages[data.latest.language].solution.sourceFile
            },
            () => console.log(this.state)
          );
        } else {
          this.setState(
            {
              solution_code: languages["JavaScript"].solution.boilerplate,
              test_code: languages["JavaScript"].test.boilerplate,
              activeFile: languages["JavaScript"].solution.sourceFile
            },
            () =>
              socket.emit(SAVE_CODE_ROOM_SNAPSHOT, {
                solution_code: this.state.solution_code,
                test_code: this.state.test_code,
                language: this.state.language,
                output: this.state.terminal,
                activeFile: this.state.activeFile
              })
          );
        }
      } else {
        this.setState({ interviewOver: true });
      }
    });
    socket.on(CODE_ROOM_USER_JOINED, ({ participants }) => {
      console.log("CODE_ROOM_USER_JOINED participants: ", participants);
      this.setState({ participants });
    });
    socket.on(CODE_ROOM_USER_LEFT, ({ participants, left }) => {
      console.log({ participants, left });
      let data = [[left.id, `${left.username}`, ` disconnected.`], ">"];
      this.setState({
        participants,
        terminal: [...this.state.terminal, ...data]
      });
    });
    socket.on(
      CHANGE_CODE_ROOM_SOLUTION,
      ({ id, solution_code, test_code, language, activeFile }) => {
        this.setState({ solution_code, test_code, language, activeFile });
      }
    );

    socket.on(RUN_CODE_ROOM_SOLUTION, () => {
      this.setState({ running_code: true });
    });

    socket.on(CODE_ROOM_TERMINAL_CHANGE, data => {
      console.log("terminal: ", data);
      this.setState({ terminal: data.output, running_code: false });
    });

    socket.on(FINISH_CODE_ROOM_INTERVIEW, () => {
      console.log("Incoming FINISH_CODE_ROOM_INTERVIEW", this.props);
      const { auth, history } = this.props;
      if (!isEmpty(auth.data) && !auth.loading) {
        history.push(`/room/${room}/playback`);
      } else {
        history.push(`/room/${room}/thanks`);
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { auth, history } = this.props;
    const { interviewOver } = this.state;
    const room = this.props.match.params._id;

    if (
      !isEqual(auth, prevProps.auth) ||
      !isEqual(interviewOver, prevState.interviewOver)
    ) {
      if (interviewOver) {
        if (!isEmpty(auth.data) && !auth.loading) {
          return history.push(`/room/${room}/playback`);
        } else {
          return history.push(`/room/${room}/thanks`);
        }
      } else {
        if (!isEmpty(auth.data) && !auth.loading) {
          return this.handleParticipants(auth.data._user.local.name);
        } else if (isEmpty(auth.data) && !auth.loading) {
          return this.setState({ showParticipantModal: true });
        }
      }
    }
  }

  componentWillUnmount() {
    const { auth } = this.props;
    console.log("componenWillUnmount");
    if (isEmpty(auth.data) && !auth.loading) {
      console.log("disconnect");
      this.props.socket.emit("disconnect");
    }
  }
  onSubmit = () => {
    const { solution_code, test_code, language, activeFile } = this.state;
    let user = this.state.participants.find(
      user => user.id === this.props.socket.id
    );
    console.log({ user });
    if (language === "HTML/CSS/JS") {
      return;
    } else if (activeFile === languages[language].solution.sourceFile) {
      this.props.socket.emit(RUN_CODE_ROOM_SOLUTION, {
        user,
        currentOutput: this.state.terminal,
        solution_code,
        test_code,
        language: languages[language].name,
        activeFile,
        type: "solution"
      });
    } else {
      this.props.socket.emit(RUN_CODE_ROOM_SOLUTION, {
        user,
        currentOutput: this.state.terminal,
        solution_code,
        test_code,
        language: languages[language].name,
        activeFile,
        type: "test"
      });
    }
  };

  reset = () =>
    this.setState(
      {
        solution_code: languages[this.state.language].solution.boilerplate,
        test_code: languages[this.state.language].test.boilerplate
      },
      () => {
        this.sendCode();
      }
    );

  handleLanguageChange = language => {
    const { solution, test } = languages[language];
    this.appendToEditor(language, solution.boilerplate, test.boilerplate);
  };

  handleFileChange = file =>
    this.setState({ activeFile: file }, () => this.sendCode());

  handleParticipants = username => {
    let roomId = this.props.match.params._id;
    console.log("handleParticipants: ", username);
    this.setState(
      {
        showParticipantModal: false
      },
      () => this.props.socket.emit(ADD_CODE_ROOM_USER, { username, roomId })
    );
  };

  handleEndInterview = () => {
    let roomId = this.props.match.params._id;

    this.setState({ showEndInterviewModal: false }, () =>
      this.props.socket.emit(FINISH_CODE_ROOM_INTERVIEW, { roomId })
    );
  };

  handleEndInterviewButtonClick = () =>
    this.setState({ showEndInterviewModal: !this.state.showEndInterviewModal });

  replaceEditor = (language, solutionFile_text = "", testFile_text = "") => {
    const { solution } = languages[language];
    this.setState({
      solution_code: solutionFile_text,
      test_code: testFile_text,
      language,
      activeFile: solution.sourceFile
    });
  };

  appendToEditor = (language, solutionFile_text = "", testFile_text = "") => {
    const { solution, test, comments } = languages[language];
    let { solution_code, test_code } = this.state;
    if (language === this.state.language) {
      if (!solution_code) {
        this.setState(
          {
            solution_code: solutionFile_text,
            activeFile: solution.sourceFile
          },
          () => this.sendCode()
        );
      }
      if (!test_code && test.testable) {
        this.setState(
          { test_code: testFile_text, activeFile: solution.sourceFile },
          () => this.sendCode()
        );
      }
    } else if (
      solution_code === languages[this.state.language].solution.boilerplate &&
      test_code === languages[this.state.language].test.boilerplate
    ) {
      this.setState(
        {
          solution_code: solutionFile_text,
          test_code: testFile_text,
          language,
          activeFile: solution.sourceFile
        },
        () => this.sendCode()
      );
    } else {
      if (solution_code === "" && test_code === "") {
        this.setState(
          {
            solution_code: solutionFile_text,
            test_code: testFile_text,
            language,
            activeFile: solution.sourceFile
          },
          () => this.sendCode()
        );
      } else {
        let splitSolutionCode = solution_code.split("\n");
        let splitTestCode = test_code.split("\n");
        let commentedSolutionCode;
        let commentedTestCode;
        if (comments.type === "single") {
          commentedSolutionCode = [
            "",
            `Your previous ${this.state.language} content is preserved below:`,
            ...splitSolutionCode
          ]
            .map(line => `${comments.prefix} ${line}`)
            .join("\n");
          commentedTestCode = [
            "",
            `Your previous ${this.state.language} content is preserved below:`,
            ...splitTestCode
          ]
            .map(line => `${comments.prefix} ${line}`)
            .join("\n");
        } else {
          commentedSolutionCode = [
            comments.prefix,
            `Your previous ${this.state.language} content is preserved below:`,
            ...splitSolutionCode,
            comments.suffix
          ].join("\n");

          commentedTestCode = [
            comments.prefix,
            `Your previous ${this.state.language} content is preserved below:`,
            ...splitTestCode,
            comments.suffix
          ].join("\n");
        }
        this.setState(
          {
            solution_code: `${solutionFile_text}\n\n\n${commentedSolutionCode}`,
            test_code: `${testFile_text}\n\n\n${commentedTestCode}`,
            language,
            activeFile: solution.sourceFile
          },
          () => this.sendCode()
        );
      }
    }
  };
  render() {
    const {
      language,
      solution_code,
      test_code,
      participants,
      showParticipantModal,
      markdownNotes,
      terminal,
      showEndInterviewModal,
      activeFile,
      running_code
    } = this.state;
    const { auth } = this.props;
    const room = this.props.match.params._id;

    const operations = (
      <Button className="ide-btn" onClick={this.reset}>
        Reset
      </Button>
    );

    if (auth.loading) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh"
          }}
        >
          <Spin size="large" />
        </div>
      );
    }
    return (
      <React.Fragment>
        <Tour />
        <div
          style={{ height: "100vh", backgroundColor: "rgba(23, 27, 33, 0.96)" }}
        >
          <NameModal
            visible={showParticipantModal}
            participants={participants}
            handleParticipants={this.handleParticipants}
          />
          <div
            style={{
              display: "flex"
            }}
          >
            <div style={{ width: "50%", padding: "8px 4px 8px 8px" }}>
              <div
                style={{
                  display: "flex",
                  padding: "10px 10px 16px",
                  justifyContent: "space-between",
                  background: "#181C22"
                }}
              >
                <Button
                  type="primary"
                  onClick={this.onSubmit}
                  style={{ backgroundColor: "#08979c", borderColor: "#08979c" }}
                  loading={running_code}
                  className="run-btn"
                >
                  Run
                  <Icon type="arrow-right" />
                </Button>
                <div style={{ display: "flex" }}>
                  {!isEmpty(auth.data) && !auth.loading ? (
                    <QuestionsModal
                      replaceEditor={this.replaceEditor}
                      appendToEditor={this.appendToEditor}
                    />
                  ) : null}
                  <Button.Group
                    className="language-select-and-info"
                    style={{ display: "flex", marginLeft: "20px" }}
                  >
                    <LanguageInfoModal language={language} />
                    <LanguageSelect
                      handleLanguageChange={this.handleLanguageChange}
                      language={language}
                    />
                  </Button.Group>
                </div>
              </div>
              <Tabs
                activeKey={activeFile}
                onChange={this.handleFileChange}
                tabBarStyle={{
                  background: "#181C22",
                  margin: 0,
                  padding: "0 10px 16px",
                  border: "none",
                  color: "rgba(191, 191, 191, 0.85)"
                }}
                className="editor-files"
              >
                <TabPane
                  tab={
                    <div className="editor-file">
                      {languages[language].solution.sourceFile}
                    </div>
                  }
                  key={languages[language].solution.sourceFile}
                >
                  <div
                    style={{
                      padding: "0px 10px",
                      background: "#181C22",
                      height: "81.7vh"
                    }}
                    className="solution-editor"
                  >
                    <CodeEditor
                      language={languages[language].name}
                      theme={"monokai"}
                      lineNumbers={true}
                      code={solution_code}
                      onEditorChange={this.onSolutionEditorChange}
                    />
                  </div>
                </TabPane>
                {languages[language].test.testable ? (
                  <TabPane
                    tab={
                      <div className="editor-file">
                        {languages[language].test.sourceFile}
                      </div>
                    }
                    key={languages[language].test.sourceFile}
                  >
                    <div
                      style={{
                        padding: "0px 10px",
                        background: "#181C22",
                        height: "81.7vh"
                      }}
                      className="test-editor"
                    >
                      <CodeEditor
                        language={languages[language].name}
                        theme={"monokai"}
                        lineNumbers={true}
                        code={test_code}
                        onEditorChange={this.onTestEditorChange}
                      />
                    </div>
                  </TabPane>
                ) : null}
              </Tabs>
            </div>
            <div style={{ width: "50%", padding: "8px 8px 8px 4px" }}>
              {isEmpty(auth.data) && !auth.loading ? (
                <React.Fragment>
                  <div
                    style={{
                      display: "flex",
                      padding: "10px 10px 16px",
                      justifyContent: "flex-end",
                      background: "#181C22"
                    }}
                  >
                    {operations}
                  </div>
                  <Terminal
                    terminal={generateCodeRoomTerminalLines(
                      terminal,
                      participants,
                      colors
                    )}
                    height="87.7vh"
                    language={language}
                    code={solution_code}
                    dependencies={test_code}
                  />
                </React.Fragment>
              ) : (
                <Tabs
                  defaultActiveKey="1"
                  onChange={() => {}}
                  tabBarExtraContent={operations}
                  tabBarStyle={{
                    background: "#181C22",
                    margin: 0,
                    padding: "0 10px 16px",
                    border: "none",
                    color: "rgba(191, 191, 191, 0.85)"
                  }}
                  className="interviewer-tabs"
                >
                  <TabPane
                    tab={<div className="interviewer-tab">{"Output"}</div>}
                    key="1"
                  >
                    <Terminal
                      terminal={generateCodeRoomTerminalLines(
                        terminal,
                        participants,
                        colors
                      )}
                      height="88vh"
                      language={language}
                      code={solution_code}
                      dependencies={test_code}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      <div className="interviewer-tab">
                        {"Interviewer Notes"}
                      </div>
                    }
                    key="2"
                  >
                    <div style={{ padding: "0px 10px", background: "#181C22" }}>
                      <MarkdownNotes
                        value={markdownNotes}
                        onMarkdownEditorChange={this.onMarkdownEditorChange}
                      />
                    </div>
                  </TabPane>
                </Tabs>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              padding: "0 10px",
              justifyContent: "space-between"
            }}
          >
            <div>
              <InviteButton room={room} />
              {this.state.participants.map((participant, i) => (
                <Button
                  key={participant.id}
                  className={"ide-btn"}
                  style={{
                    color: colors[i],
                    borderColor: colors[i],
                    border: "none",
                    borderBottom: "1px solid",
                    marginRight: "8px",
                    borderRadius: "4px 4px 0px 0px"
                  }}
                >
                  {participant.username}
                </Button>
              ))}
            </div>
            {!isEmpty(auth.data) && !auth.loading ? (
              <EndInterviewModal
                visible={showEndInterviewModal}
                handleEndInterview={this.handleEndInterview}
                handleEndInterviewButtonClick={
                  this.handleEndInterviewButtonClick
                }
              />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Editor = props => (
  <SocketContext.Consumer>
    {socket => <IDE socket={socket} {...props} />}
  </SocketContext.Consumer>
);

const mapStateToProps = ({ auth }) => ({
  auth
});
export default connect(
  mapStateToProps,
  { fetchUser }
)(Editor);
