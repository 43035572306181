import React from "react";

const ThankYou = () => {
  return (
    <div
      style={{
        background: "rgba(23, 27, 33, 0.96)",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <h1 style={{ color: "white" }}>Your Interview Has Ended.</h1>
    </div>
  );
};

export default ThankYou;
