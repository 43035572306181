import { combineReducers } from "redux";
import auth from "./authReducers";
import { compilers } from "./codeReducers";
import { interviews, interview } from "./interviewReducers";
import { account, teamMembers, invite } from "./accountReducers";
import { questions, tests } from "./questionReducers";
import { plans, subscription, invoice } from "./billingReducers";
import tour from "./tourReducers";

export default combineReducers({
  auth,
  compilers,
  interviews,
  account,
  teamMembers,
  invite,
  interview,
  questions,
  tests,
  plans,
  subscription,
  invoice,
  tour
});
